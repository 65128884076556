export let colors = {
  "#5BB8DB": 0,
  "#F0DC68": 0,
  "#CB98F7": 0,
  "#78DAB3": 0,
};

export const getColor = () => {
  let keys = Object.keys(colors);
  let min = colors[keys[0]];
  let selectedColor = keys[0];
  let i;

  for (i = 1; i < keys.length; i++) {
    let value = colors[keys[i]];
    if (value < min) {
      selectedColor = keys[i];
      min = value;
    }
  }

  colors[selectedColor] += 1;

  return selectedColor;
};
