import React, { useEffect } from "react";
import "./index.scss";

const ProductRaw = ({ title, price, qty }) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="ProductRaw">
      <div className="qty">x{qty || 1}</div>
      <div className="title">{title}</div>
      <div className="price">
        {price}
        <span>دينار</span>
      </div>
    </div>
  );
};

export default ProductRaw;
