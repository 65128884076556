export const getAddressInfo = (lat, lng, callback) => {
  return (dispatch) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?language=ar&key=AIzaSyCm7TROx-3ywRQqKJf4ziZgRB_JQacJmN0&latlng=${lat},${lng}&v=weekly`
    )
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};
