import React from "react";
import "./index.scss";
import { Placeholder } from "semantic-ui-react";

const ChooseLocationPlaceHolder = () => {
  return (
    <Placeholder className="ChooseLocationPlaceHolder">
      <Placeholder.Image />
    </Placeholder>
  );
};

export default ChooseLocationPlaceHolder;
