import React, { useState, useEffect } from "react";
import "./index.scss";
import { Form, Icon, Loader } from "semantic-ui-react";
import ProductPlaceholder from "../../PlaceHolders/ProductPlaceholder";
import Product from "../../components/Product";
import { GET_REQ } from "../../helpers/requests";
import SmallCategory from "../../components/SmallCategory";
import SmallCategoryPlaceholder from "../../PlaceHolders/SmallCategoryPlaceholder";

const CategoryPage = ({ match }) => {
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);

  const [productsLoading, setProductsLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [searchloading, setSearchLoading] = useState(false);

  const [currentCategoryId, setCurrentCategoryId] = useState(match?.params?.id);
  const [currentMainCategoryId, setCurrentMainCategoryId] = useState(null);

  const [currentSubCategory, setCurrentSubCategory] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchDone, setSearchDone] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [reatchTheLimit, setReatchTheLimit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const productsLimt = 20;

  const getProducts = async () => {
    setProductsLoading(true);
    setSearchLoading(true);
    setCurrentPage(1);

    let res = await GET_REQ(`products`, {
      category_path: currentCategoryId,
      sort: "sort",
      limit: productsLimt,
      page: 1,
    });

    if (res?.data?.status === "success") {
      setProducts(res?.data?.data);
    } else {
      setProducts(null);
    }

    setProductsLoading(false);
    setSearchLoading(false);
    setFirstLoad(true);

    setTimeout(() => {
      setFirstLoad(false);
    }, 500);
  };

  const getDataOnScroll = async () => {
    setFetching(true);
    let page = currentPage + 1;

    setCurrentPage(page);

    let headers = {
      category_path: currentCategoryId,
      sort: "sort",
      limit: productsLimt,
      page,
    };

    searchValue && (headers["search"] = searchValue);

    let res = await GET_REQ("products", {
      ...headers,
    });
    if (res?.data?.data?.length > 0) {
      setProducts([...products, ...res?.data?.data]);
    } else {
      setReatchTheLimit(true);
    }
    setFetching(false);
  };

  const onScrollPage = ({ target }) => {
    if (target?.scrollHeight - target?.scrollTop - 600 <= 500) {
      if (
        products?.length > productsLimt - 1 &&
        !fetching &&
        !reatchTheLimit &&
        !productsLoading &&
        !searchloading &&
        !categoriesLoading &&
        !firstLoad
      ) {
        getDataOnScroll();
      }
    }
  };

  const getCategories = async () => {
    setCategoriesLoading(true);
    let res = await GET_REQ(`categories`, { sort: "sort" });

    setCategoriesLoading(false);

    if (res?.data?.status === "success") {
      setCategories(res?.data?.data.filter(({ is_sub_category }) => !is_sub_category));
    }

    // select the default one (from params id)

    if (match?.params?.id && res?.data?.data) {
      let { sub_categories } = res?.data?.data?.find((item) => item?._id === match?.params?.id);

      selectMainCategory(match?.params?.id, sub_categories);
    }
  };

  const clearSearch = () => {
    setSearchDone(false);
    setSearchValue("");
  };

  const handleSearchChange = (event, { value }) => {
    setSearchValue(value);
    setSearchDone(false);
  };

  const selectMainCategory = (id, sub_categories) => {
    if (sub_categories && sub_categories?.length > 0) {
      setCurrentSubCategory(sub_categories);
      setCurrentMainCategoryId(id);
      setCurrentCategoryId(sub_categories[0]?._id);
    } else {
      setCurrentSubCategory([]);
      setCurrentCategoryId(id);
      setCurrentMainCategoryId(id);
    }
  };

  const selectSubCategory = (id) => {
    setCurrentCategoryId(id);
  };

  const getSearchResult = async () => {
    if (searchValue) {
      setSearchLoading(true);
      let res = await GET_REQ("products", {
        search: searchValue,
        category_path: currentCategoryId,
        sort: "sort",
        limit: productsLimt,
        page: 1,
      });
      setSearchLoading(false);
      setSearchDone(true);

      if (res?.data?.status === "success" && res?.data?.data?.length > 0) {
        setProducts(res?.data?.data);
      } else {
        setProducts(null);
      }

      setSearchDone(true);
    }
  };

  useEffect(() => {
    getProducts();

    return () => {};
  }, [currentCategoryId]);

  useEffect(() => {
    getCategories();
    return () => {};
  }, []);

  return (
    <div className="Category-page" onScroll={onScrollPage}>
      <div className="categories-container">
        {categoriesLoading ? (
          <>
            <SmallCategoryPlaceholder />
            <SmallCategoryPlaceholder />
            <SmallCategoryPlaceholder />
            <SmallCategoryPlaceholder />
            <SmallCategoryPlaceholder />
            <SmallCategoryPlaceholder />
          </>
        ) : (
          <>
            {categories?.map(({ _id, name, image, sub_categories }) => (
              <SmallCategory
                id={_id}
                onClick={() => selectMainCategory(_id, sub_categories)}
                title={name}
                selected={currentMainCategoryId === _id}
                imgSrc={image}
              />
            ))}
          </>
        )}
      </div>
      <div className="sub-categories">
        {currentSubCategory &&
          currentSubCategory?.map(({ _id, name_ar }) => (
            <div
              className={["sub-category", currentCategoryId === _id ? "selected" : ""].join(" ")}
              onClick={() => selectSubCategory(_id)}
            >
              <p>{name_ar}</p>
            </div>
          ))}
      </div>

      <Form onSubmit={getSearchResult} className="search-bar">
        <Form.Input
          loading={searchloading}
          disabled={searchloading}
          icon={
            searchDone && searchValue ? (
              <Icon onClick={clearSearch} name="close" link />
            ) : (
              <Icon onClick={getSearchResult} name="search" link />
            )
          }
          placeholder={"حليب ، طماطم ، مناديل…"}
          onChange={handleSearchChange}
          className="search-input"
          value={searchValue}
          // value={this.state.query}
          // onChange={this.handleInputChange}
        />
        {/* <Search
          /> */}
      </Form>

      <div className="content">
        {productsLoading ? (
          <>
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
          </>
        ) : (
          <>
            {products?.length > 0 ? (
              products?.map(({ title_ar, size, thumbnail, price, _id, favorite_users }, index) => (
                <Product
                  favorite_users={favorite_users}
                  key={_id + index}
                  imgSrc={thumbnail}
                  title={`${title_ar}`}
                  price={price}
                  _id={_id}
                  timeoutScroll={1000}
                />
              ))
            ) : (
              <p>لا يوجد نتائج مطابقة للبحث</p>
            )}
          </>
        )}
      </div>
      <div className={["loader-container", fetching ? "fetching" : ""].join(" ")}>
        <Loader active={fetching} />
      </div>
    </div>
  );
};

export default CategoryPage;
