import React, { useEffect } from "react";
import "./index.scss";
import Category from "../Category";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import CategoryPlaceholder from "../../PlaceHolders/CategoryPlaceholder";
import { GET_REQ } from "../../helpers/requests";

const Categories = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const openCategoriesPage = () => {
    history.push("/categories");
  };

  const getData = async () => {
    setLoading(true);
    let res = await GET_REQ(`categories`, { sort: "sort" });
    setData(res?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="Categories-container">
      <div className="header">
        <p className="title">التصنيفات</p>
        <p className="show-all" onClick={openCategoriesPage}>
          شاهد الكل
        </p>
      </div>
      <div className="content">
        {loading ? (
          <>
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
          </>
        ) : (
          <>
            {data?.map(({ _id, name, color, image }) => (
              <Category
                key={_id}
                id={_id}
                colord={color}
                title={name}
                imgSrc={image}
                width={132}
                height={140}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Categories;
