import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={22} height={19} viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{"C18BCA95-9E73-4B92-91DB-D678B61BFDC8@1"}</title>
      <path
        d="M16.07.01c-1.83 0-3.434.816-4.64 2.36a7.81 7.81 0 00-.43.61c-.127-.199-.27-.405-.43-.61C9.363.825 7.76.01 5.93.01 2.473.01 0 2.903 0 6.377c0 3.971 3.255 7.715 10.555 12.137a.859.859 0 00.89 0C18.745 14.093 22 10.349 22 6.378 22 2.906 19.53.009 16.07.009zm1.94 11.313c-1.519 1.696-3.815 3.481-7.01 5.45-3.195-1.969-5.491-3.754-7.01-5.45C2.46 9.618 1.718 8.001 1.718 6.378c0-2.501 1.69-4.65 4.21-4.65 1.284 0 2.379.558 3.254 1.66.7.88.995 1.789.997 1.795a.86.86 0 001.64 0c.003-.008.29-.89.966-1.756.88-1.127 1.985-1.699 3.284-1.699 2.524 0 4.211 2.15 4.211 4.65 0 1.623-.742 3.24-2.27 4.945z"
        fill={props?.stroke || "#FFF"}
        fillRule="nonzero"
        stroke={props?.stroke || "#FFF"}
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default SvgComponent;
