import React from "react";
import { Placeholder, Segment } from "semantic-ui-react";
import "./index.scss";

const OrderCardPlaceHolder = () => {
  return (
    <Segment raised className="OrderCardPlaceHolder">
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
          <Placeholder.Line length="short" />
          <Placeholder.Line className="left-line" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  );
};

export default OrderCardPlaceHolder;
