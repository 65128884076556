import { GET_REQ } from "../../helpers/requests";
import actionTypes from "../actionTypes";
import { clear_cart } from "./cart";

export const login = (payload) => {
  if (payload?.token) {
    localStorage.setItem("u-token", payload?.token);
  }

  localStorage.removeItem("g-token");

  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_USER,
      payload,
    });
  };
};

export const update_user_data = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_DATA,
      payload,
    });
  };
};

export const fetch_user = (callback) => {
  return async (dispatch) => {
    let res = await GET_REQ("users/info");

    if (res?.data?.status === "success") {
      dispatch(login(res?.data?.data));
      callback && callback(res?.data?.data);
    }
  };
};

export const logout = () => {
  localStorage.removeItem("u-token");

  return async (dispatch) => {
    dispatch(clear_cart());

    dispatch({
      type: actionTypes.LOGOUT_USER,
    });
  };
};
