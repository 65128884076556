import React from "react";
import "./index.scss";
import { Placeholder } from "semantic-ui-react";

const CategoryPlaceholder = () => {
  return (
    <Placeholder className="CategoryPlaceholder">
      <Placeholder.Image />
    </Placeholder>
  );
};

export default CategoryPlaceholder;
