import React from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";

const AcconutCreatedSuccessfully = () => {
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <div className="account-created-successfully-page">
      <div className="content">
        <img className="success-reset-password" alt="" src="/assets/icons/change_success.svg" />
        <p className="title">تم انشاء حسابك بنجاح! </p>
        <p className="desc">
          أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص
        </p>
        <Button onClick={goToHomePage} className="secondary submit-button">
          الذهاب للرئيسية
        </Button>
      </div>
    </div>
  );
};

export default AcconutCreatedSuccessfully;
