import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Icon, Image, Loader, Placeholder } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { add_to_cart, add_to_favorite, update_cart } from "../../redux/actions/cart";
import { checkFavoriteProduct } from "../../helpers/helper";
import BottomModal from "../BottomModal";
import useQueryString from "use-query-string";
import { useHistory } from "react-router";
import Qty from "../Qty";
import { DELETE_REQ } from "../../helpers/requests";

const Product = ({
  title,
  price = 0,
  market_price = 0,
  imgSrc,
  isFavorite,
  _id,
  favorite_users,
  timeoutScroll = 0,
  removeFavoriteProduct,
  out_of_stock,
  notAvailable = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const productRef = useRef();
  const [query] = useQueryString(window.location);

  const userState = useSelector((state) => state.UserReducer);
  const addressesState = useSelector((state) => state.AddressesReducer);
  const cartState = useSelector((state) => state.CartReducer);

  const [favorite, setFavorite] = useState(isFavorite || false);
  const [loading, setLoading] = useState(false);
  const [addLocationModal, setAddLocationModal] = useState(false);

  const [favoriteloading, setFavoriteLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(true);
  const [addedToCart, setAddedToCart] = useState(false);
  const [qtyCount, setqtyCount] = useState(0);
  const [qtyLoading, setQtyLoading] = useState(false);
  const [deleteItemloading, setDeleteItemLoading] = useState(false);

  const switchToFavorite = async () => {
    let switched = !favorite;

    if (!favoriteloading) {
      setFavoriteLoading(true);

      let added = await add_to_favorite(_id);

      setFavoriteLoading(false);
      if (added) {
        setFavorite(switched);
        switched === false && removeFavoriteProduct && removeFavoriteProduct(_id);
      }
    }
  };

  const success_callback = () => {
    setLoading(false);
    setAddedToCart(true);
    setTimeout(() => {
      setAddedToCart(false);
    }, 5000);
  };

  const error_callback = () => {
    setLoading(false);
  };

  const addToCart = async () => {
    if (!addressesState?.selected_address?.formatted_address) {
      setAddLocationModal(true);
      return;
    }

    setLoading(true);

    dispatch(add_to_cart({ _id, quantity: 1 }, success_callback, error_callback));
  };

  const increaseQty = () => {
    setQtyLoading(true);

    let success_callback_b = () => {
      setQtyLoading(false);
      setqtyCount(qtyCount + 1);
    };

    let error_callback_b = () => {
      setQtyLoading(false);
    };

    dispatch(add_to_cart({ _id, quantity: 1 }, success_callback_b, error_callback_b));
  };

  const decreaseQty = () => {
    if (qtyCount - 1 <= 0) {
      return;
    }

    setQtyLoading(true);

    let success_callback_b = () => {
      setQtyLoading(false);
      setqtyCount(qtyCount - 1);
    };

    let error_callback_b = () => {
      setQtyLoading(false);
    };

    dispatch(add_to_cart({ _id, quantity: -1 }, success_callback_b, error_callback_b));
  };

  const deleteItem = async () => {
    setDeleteItemLoading(true);
    // index
    let index;

    cartState?.items?.length > 0 &&
      cartState.items.map((product, indexx) => {
        if (product?.product_id === _id) {
          index = indexx;
          return indexx;
        } else {
          return false;
        }
      });

    let res = await DELETE_REQ(`cart/${cartState?._id}/${index}`);

    if (res?.data?.status === "success") {
      dispatch(update_cart(res?.data?.data));
    }

    setTimeout(() => {
      setDeleteItemLoading(false);
    }, 1000);
  };

  const ImageLoaded = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    if (query?.pid === _id) {
      setTimeout(() => {
        productRef?.current &&
          productRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });

        dispatch(add_to_cart({ _id, quantity: 1 }, success_callback, error_callback));
      }, timeoutScroll);
    }
    return () => {};
  }, [query, productRef, timeoutScroll]);
  useEffect(() => {
    if (userState?._id && favorite_users) {
      let check = checkFavoriteProduct(userState?._id, favorite_users);
      setFavorite(isFavorite || check);
    }
    return () => {};
  }, [userState, favorite_users, isFavorite]);

  useEffect(() => {
    if (cartState?.items) {
      let product = cartState?.items?.find((productObj) => productObj?.product_id === _id);

      setqtyCount(product?.quantity);
    }
    return () => {};
  }, [cartState]);

  return (
    <div className="product-container" ref={productRef}>
      <BottomModal
        setOpen={setAddLocationModal}
        open={addLocationModal}
        product_id={_id}
        route={history?.location?.pathname}
      />
      {market_price / price > 1 && (
        <div className="discount">
          <p>
            خصم <span>{(1 - price / market_price) * 100}%</span>
          </p>
        </div>
      )}
      <div onClick={switchToFavorite} className="favorite">
        {favoriteloading ? (
          <Loader active size="small" inline="centered" />
        ) : (
          <Icon
            className={favorite ? "selected" : ""}
            size="large"
            name={favorite ? "heart" : "heart outline"}
          />
        )}
      </div>
      <div className="product-image">
        {
          <Image
            style={{ display: imageloading ? "none" : "block" }}
            src={imgSrc}
            onLoad={ImageLoaded}
          />
        }
        {imageloading && (
          <Placeholder>
            <Placeholder.Image style={{ width: 150, height: 148 }} />
          </Placeholder>
        )}
        {out_of_stock && !imageloading && (
          <div className="out-of-stock">
            <p>مباع</p>
          </div>
        )}
      </div>
      <div className="title">
        <p>{title}</p>
      </div>
      <div className="price-cart">
        <div className="price-container">
          <div className="price">
            {price}
            <span> دينار </span>
          </div>
          {market_price / price > 1 && (
            <di className="old-price">
              {market_price}
              <span> دينار </span>
            </di>
          )}
        </div>

        {!out_of_stock && (qtyCount < 1 || qtyCount === undefined) && (
          <div className={["add-to-cart", loading ? "loading" : ""].join(" ")} onClick={addToCart}>
            {loading ? (
              <Loader active size="mini" inline="centered" style={{ width: 10 }} />
            ) : (
              <p>+</p>
            )}
          </div>
        )}
      </div>
      {qtyCount > 0 && (
        <Qty
          loading={qtyLoading || deleteItemloading}
          notAvailable={notAvailable}
          count={qtyCount}
          onChange={(count) => setqtyCount(count)}
          index={cartState?.items?.filter((product) => product?.product_id === _id)}
          increase={increaseQty}
          decrease={decreaseQty}
          deleteItem={deleteItem}
          allowDeleteItem
        />
      )}
      {addedToCart && <p className="added-to-cart">تم إضافة المنتج للسلة </p>}
    </div>
  );
};

export default Product;
