import React from "react";
import { useHistory } from "react-router";
import { Button, Modal, Transition } from "semantic-ui-react";
import "./index.scss";

const BottomModal = ({ open, setOpen, product_id, route, query }) => {
  const history = useHistory();

  const goToAddLocationMap = () => {
    if (query?.q) {
      history.push(`/chooseLocationMap?pid=${product_id}&q=${query?.q}`);
    } else {
      history.push(`/chooseLocationMap?pid=${product_id}&r=${route}`);
    }
  };

  return (
    <Transition visible={open} animation="fade up" duration={300}>
      <Modal
        dimmer="blurring"
        className="bottom-modal"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Content>
          <Modal.Description>
            <img className="icon" alt="" src="/assets/icons/location_white.svg" />

            <p className="title">يرجى اختيار عنوان التوصيل</p>

            <Button className="secondary choose-location-button" onClick={goToAddLocationMap}>
              اختر العنوان
            </Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Transition>
  );
};

export default BottomModal;
