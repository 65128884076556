import { combineReducers } from "redux";

import UserReducer from "./user";
import SearchReducer from "./search";
import CartReducer from "./cart";
import AddressesReducer from "./addresses";

const rootReducer = combineReducers({
  UserReducer,
  SearchReducer,
  CartReducer,
  AddressesReducer,
});

export default rootReducer;
