import React from "react";
import "./index.scss";
import { Placeholder } from "semantic-ui-react";

const ProductPlaceholder = () => {
  return (
    <Placeholder className="ProductPlaceholder">
      <Placeholder.Image />
    </Placeholder>
  );
};

export default ProductPlaceholder;
