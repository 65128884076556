import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Menu, Segment, Sidebar, Button } from "semantic-ui-react";
import { logout } from "../../redux/actions/user";
import NavBar from "../NavBar";
import "./index.scss";

const SideBar = ({ children }) => {
  const { loggedIn, username } = useSelector((state) => state.UserReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [searchPage, setSearchPage] = useState(false);
  const [categoryPage, setCategoryPage] = useState(false);

  const goToHomePage = () => {
    setSidebarVisible(false);
    history.push("/");
  };

  const goToSignup = () => {
    setSidebarVisible(false);
    history.push("/signup");
  };

  const goToLogin = () => {
    setSidebarVisible(false);
    history.push("/login");
  };

  const goToSupportedArea = () => {
    setSidebarVisible(false);
    history.push("/supportedareas");
  };

  const goToMyProfile = () => {
    setSidebarVisible(false);
    history.push("/profile");
  };

  const goToMyOrders = () => {
    setSidebarVisible(false);
    history.push("/myorders");
  };

  const logOut = () => {
    dispatch(logout());
    setSidebarVisible(false);
    history.push("/");
  };

  const closeSideBar = () => {
    setSidebarVisible(false);
  };

  const goToAboutLebeeba = () => {
    setSidebarVisible(false);
    history.push("/about");
  };

  const goToPrivacyPolicy = () => {
    setSidebarVisible(false);
    history.push("/privacyPolicy");
  };

  const goToTermsandConditions = () => {
    setSidebarVisible(false);
    history.push("/termsandConditions");
  };

  useEffect(() => {
    if (history?.location?.pathname === "/chooseLocationMap") {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }

    if (history?.location?.pathname === "/search") {
      setSearchPage(true);
    } else {
      setSearchPage(false);
    }

    if (history?.location?.pathname.includes("/Category")) {
      setCategoryPage(true);
    } else {
      setCategoryPage(false);
    }

    return () => {};
  }, [history?.location?.pathname]);

  return (
    <Sidebar.Pushable
      as={Segment}
      className={[sidebarVisible ? "sidebar-visible" : "", hideFooter ? "footer-hidden" : "", searchPage ? "search-page" : "", categoryPage ? "category-page" : ""].join(" ")}
    >
      <Sidebar as={Menu} animation="overlay" icon="labeled" className="Sidebar" inverted direction="right" onHide={() => setSidebarVisible(false)} vertical visible={sidebarVisible} width="thin">
        <Menu.Item>
          <div className="header">
            <img className="logo" onClick={closeSideBar} alt="" src="/assets/icons/Close_icon.svg" style={{ width: 16, color: "#253b69" }} />
            <img className="logo" onClick={goToHomePage} alt="" src="/assets/icons/logo.png" style={{ width: 220 }} />
            <div></div>
          </div>
        </Menu.Item>
        <Menu.Item className="information">
          <div className="username">
            {loggedIn && <p>أهلاً {username}!</p>}
            {!loggedIn && <p>أهلاً بك في لبيبة!</p>}
            {!loggedIn && (
              <div className="buttons">
                <Button onClick={goToLogin} className="primary">
                  تسجيل الدخول
                </Button>
                <Button onClick={goToSignup} className="secondary">
                  انشاء حساب
                </Button>
              </div>
            )}
          </div>
        </Menu.Item>
        <Menu.Item onClick={goToSupportedArea} as="a" className="link">
          <img alt="" src="/assets/icons/location.svg" />
          <p className="title">المناطق المخدومة</p>
        </Menu.Item>
        {loggedIn && (
          <Menu.Item onClick={goToMyProfile} as="a" className="link">
            <img alt="" src="/assets/icons/profile.svg" />
            <p className="title">حسابي</p>
          </Menu.Item>
        )}
        {loggedIn && (
          <Menu.Item onClick={goToMyOrders} as="a" className="link">
            <img alt="" src="/assets/icons/my-orders.svg" />
            <p className="title">طلباتي</p>
          </Menu.Item>
        )}
        {loggedIn && (
          <Menu.Item onClick={logOut} as="a" className="link">
            <img alt="" src="/assets/icons/logout.svg" />
            <p className="title">تسجيل الخروج</p>
          </Menu.Item>
        )}
        <Menu.Item onClick={goToAboutLebeeba} as="a" className="link">
          <img alt="" src="/assets/icons/Lebeeba.svg" />
          <p className="title">عن لبيبة</p>
        </Menu.Item>
        <Menu.Item className="bottom">
          <p className="title">تابعنا على مواقع التواصل الإجتماعي</p>
          <div className="social">
            <img onClick={() => {}} alt="" src="/assets/icons/social/social_facebook.svg" />
            <img onClick={() => {}} alt="" src="/assets/icons/social/social_twitter.svg" />
            <img onClick={() => {}} alt="" src="/assets/icons/social/social_instgram.svg" />
            <img onClick={() => {}} alt="" src="/assets/icons/social/social_youtube.svg" />
          </div>
        </Menu.Item>
        <Menu.Item className="footer">
          <div className="privacy">
            <p onClick={goToTermsandConditions}>الشروط والاحكام</p>|<p onClick={goToPrivacyPolicy}>الأمن والخصوصية</p>
          </div>
          <div>
            <p className="copy">جميع الحقوق محفوظة. © لبيبة 2021</p>
          </div>
        </Menu.Item>
      </Sidebar>

      <Sidebar.Pusher>
        <NavBar history={history} menuVisible={sidebarVisible} setMenuVisible={setSidebarVisible} />

        {children}

        {/* <Footer /> */}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default SideBar;
