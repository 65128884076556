import React, { useState, useEffect } from "react";
import { Button, Icon, Image } from "semantic-ui-react";
import useQueryString from "use-query-string";
import { GET_REQ } from "../../helpers/requests";
import "./index.scss";
import OrderInsideCard from "./OrderInsideCard";
import OrderTimeLine from "./OrderTimeLine";

const Order = ({ match }) => {
  const [query] = useQueryString(window.location);

  const [orderAdded, setOrderAdded] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    let res = await GET_REQ(`orders/${match?.params?.id}`);

    if (res?.data?.status === "success") {
      setOrderData(res?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query?.q === "added") {
      setOrderAdded(true);
    } else {
      setOrderAdded(false);
    }

    return () => {};
  }, [query?.q]);

  useEffect(() => {
    if (match?.params?.id) {
      getData();
    }
    return () => {};
  }, []);

  return (
    <div
      className={["Order-page", orderData ? "" : "full-height"].join(" ")}
      style={{ marginTop: 20 }}
    >
      {orderAdded && (
        <div className="order-added-success">
          <img className="logo" alt="" src="/assets/icons/change_success.svg" />
          <p className="title">تمت اضافة الطلب بنجاح!</p>
        </div>
      )}

      {query?.out && (
        <div className="out-of-service">
          <p className="title">
            لبيبة جاهزة لتوصيل جميع طلباتكم ، تم استلام طلبك و سيتم توصيله في أقرب وقت ممكن .
          </p>

          <p className="title">آخذين بعين الاعتبار قرارات أوامر الدفاع .</p>
        </div>
      )}

      <OrderInsideCard orderData={orderData} />

      <OrderTimeLine orderData={orderData} loading={loading} />

      {orderData?.payment_details?.driver_name && (
        <div className="delivery-person">
          <p className="title">يمكنك التواصل مع مندوب لبيبة اذا كان لديل اي استفسار</p>
          <div className="details">
            <div className="image">
              <Image src={"/assets/icons/timeline/accepted.svg"} />
            </div>
            <div className="name">{orderData?.payment_details?.driver_name}</div>
            <div className="call">
              <Button labelPosition="right" className="call-button icon">
                <Icon name={"call"} color="white" />
                اتصل
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
