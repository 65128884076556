import axios from "axios";
// import store from "../redux";

axios.defaults.headers.Accept = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.timeout = 60 * 5 * 1000;

// let storeState = store.getState();
axios.interceptors.request.use(
  //intercept a request
  async (config) => {
    let uToken = localStorage.getItem("u-token");
    let gToken = localStorage.getItem("g-token");

    if ((uToken || gToken) && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${uToken || gToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response?.data) {
      if (response?.data?.token) {
        if (
          response?.data?.message === "add to cart successfully" ||
          response?.data?.message === "add favorite success" ||
          response?.data?.message === "add address successfully"
        ) {
          localStorage.setItem("g-token", response?.data?.token);
        } else {
          localStorage.setItem("u-token", response?.data?.token);
        }
      }
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const GET_REQ = async (endpoint, params = "", headers = {}) => {
  return await axios
    .get(process.env.REACT_APP_API_URL + endpoint + getParams(params), { headers })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error.response;
      }
    );
};

export const GET_REQ_URL = async (endpoint, params = "", headers = {}) => {
  return await axios.get(endpoint + params, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

export const POST_REQ = async (endpoint, body = {}, headers = {}) => {
  return await axios.post(process.env.REACT_APP_API_URL + endpoint, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

export const PATCH_REQ = async (endpoint, body = {}, headers = {}) => {
  return await axios.patch(process.env.REACT_APP_API_URL + endpoint, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

export const PUT_REQ = async (endpoint, body = {}, headers = {}) => {
  return await axios.put(process.env.REACT_APP_API_URL + endpoint, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

export const DELETE_REQ = async (endpoint, data = {}, headers = {}) => {
  try {
    const response = await axios.delete(process.env.REACT_APP_API_URL + endpoint, {
      data,
      headers,
    });
    return response;
  } catch (error) {}
};

const getParams = (obj) => {
  var str = "?";

  if (obj) {
    for (var key in obj) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
  }

  return str;
};
