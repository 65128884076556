import React from "react";
import { Image, Transition } from "semantic-ui-react";
import "./index.scss";

const OrderTimeLine = ({ orderData, loading }) => {
  const orderedStatuses = {
    received: 0,
    "assigned to shopper": 0,
    "start picking": 1,
    "preparation done": 1,
    "shopper in way": 2,
    "shopper arrived user": 2,
    delivered: 3,
  };

  const getOrderStatus = (status) => {
    return (
      status && orderData?.status && orderedStatuses[status] <= orderedStatuses[orderData?.status]
    );
  };

  return (
    <Transition visible={!loading && orderData?.status} animation="fade up" duration={500}>
      <div className="OrderTimeLine">
        <p className="title">تتبع الطلب</p>
        <div className="timelines">
          <div className="right">
            {(getOrderStatus("received") || getOrderStatus("assigned to shopper")) && (
              <div className="timeline">
                <Image src={"/assets/icons/timeline/accepted.svg"} />
                <div className="border" />
              </div>
            )}
            {(getOrderStatus("start picking") || getOrderStatus("preparation done")) && (
              <div className="timeline">
                <Image src={"/assets/icons/timeline/preparation.svg"} />
                <div className="border" />
              </div>
            )}
            {(getOrderStatus("shopper in way") || getOrderStatus("shopper arrived user")) && (
              <div className="timeline">
                <Image src={"/assets/icons/timeline/on_way.svg"} />
                <div className="border" />
              </div>
            )}
            {getOrderStatus("delivered") && (
              <div className="timeline">
                <Image src={"/assets/icons/timeline/delivered.svg"} />
                <div className="border" />
              </div>
            )}
          </div>
          <div className="left">
            {getOrderStatus("received") && (
              <div className="timeline">
                <p className="title">قبول الطلب</p>
                <p className="desc">تم استلام طلبك</p>
              </div>
            )}
            {getOrderStatus("preparation done") && (
              <div className="timeline">
                <p className="title">تحضير الطلب</p>
                <p className="desc">تم تحضير طلبك</p>
              </div>
            )}
            {getOrderStatus("shopper in way") && (
              <div className="timeline">
                <p className="title">توصيل الطلب</p>
                <p className="desc">طلبك في الطريق</p>
              </div>
            )}
            {getOrderStatus("delivered") && (
              <div className="timeline">
                <p className="title">تسليم الطلب</p>
                <p className="desc">طلبك عالباب</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default OrderTimeLine;
