import React from "react";
import "./index.scss";

const Banner = ({ title, desc, imageSrc, children, style, imageStyle }) => {
  return (
    <div style={style} className="banner-container">
      <div className="content-container">
        <p className="title">{title}</p>
      </div>
      <img style={{ width: 150 }} className="image" alt="" src={imageSrc} />
    </div>
  );
};

export default Banner;
