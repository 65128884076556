import React from "react";
import "./index.scss";
import Product from "../Product";
import ProductPlaceholder from "../../PlaceHolders/ProductPlaceholder";
import { useHistory } from "react-router";

const ProductsGroup = ({ _id, title, products, loading }) => {
  const history = useHistory();

  const openCategoriesPage = () => {
    history.push(`/Category/${_id}`);
  };

  return (
    <div className="ProductsGroup-container">
      <div className="header">
        <p className="title">{title}</p>
        <p className="show-all" onClick={openCategoriesPage}>
          شاهد الكل
        </p>
      </div>
      <div className="content">
        {loading ? (
          <>
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
          </>
        ) : (
          <>
            {products?.map(
              (
                {
                  title_ar,
                  size,
                  thumbnail,
                  price,
                  _id,
                  favorite_users,
                  out_of_stock,
                  market_price,
                },
                index
              ) => (
                <Product
                  key={_id}
                  favorite_users={favorite_users}
                  imgSrc={thumbnail}
                  title={`${title_ar}`}
                  price={price}
                  _id={_id}
                  out_of_stock={out_of_stock}
                  market_price={market_price}
                />
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsGroup;
