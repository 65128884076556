import * as React from "react";

function CartSvg(props) {
  return (
    <svg width={19} height={22} viewBox="0 0 19 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{"FD8BC52B-1078-4E88-9704-E8B428C027FD@1"}</title>
      <path
        d="M17.703 5.156h-2.619A5.852 5.852 0 009.281 0a5.852 5.852 0 00-5.803 5.156H.86a.86.86 0 00-.859.86V21.14c0 .474.385.859.86.859h16.843a.86.86 0 00.86-.86V6.017a.86.86 0 00-.86-.86zM9.281 1.72c2.04 0 3.74 1.489 4.068 3.437H5.214A4.131 4.131 0 019.28 1.72zm7.563 18.562H1.719V6.875h1.718v2.578a.86.86 0 101.72 0V6.875h8.25v2.578a.86.86 0 101.718 0V6.875h1.719v13.406z"
        fill={props?.stroke || "#FFF"}
        fillRule="nonzero"
        stroke={props?.stroke || "#FFF"}
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default CartSvg;
