import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Icon, Image, Loader, Placeholder } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { add_to_cart, add_to_favorite } from "../../redux/actions/cart";
import { checkFavoriteProduct } from "../../helpers/helper";
import useQueryString from "use-query-string";
import BottomModal from "../BottomModal";
import { useHistory } from "react-router";

const SearchProduct = ({
  id,
  title,
  price = 0,
  imgSrc,
  isFavorite,
  favorite_users,
  out_of_stock,
  timeoutScroll = 0,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const productRef = useRef();
  const userState = useSelector((state) => state.UserReducer);
  const addressesState = useSelector((state) => state.AddressesReducer);
  const [query] = useQueryString(window.location);

  const [addLocationModal, setAddLocationModal] = useState(false);
  const [favorite, setFavorite] = useState(isFavorite);
  const [addedToCart, setAddedToCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [favoriteloading, setFavoriteLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(true);

  const switchToFavorite = async () => {
    if (!favoriteloading) {
      setFavoriteLoading(true);

      let added = await add_to_favorite(id);

      setFavoriteLoading(false);
      if (added) {
        setFavorite(!favorite);
      }
    }
  };

  const success_callback = () => {
    setLoading(false);
    setAddedToCart(true);
    setTimeout(() => {
      setAddedToCart(false);
    }, 5000);
  };

  const error_callback = () => {
    setLoading(false);
  };

  const addToCart = async () => {
    if (!addressesState?.selected_address?.formatted_address) {
      setAddLocationModal(true);
      return;
    }

    setLoading(true);

    dispatch(add_to_cart({ _id: id, quantity: 1 }, success_callback, error_callback));
  };

  const ImageLoaded = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    if (query?.pid === id) {
      setTimeout(() => {
        productRef?.current &&
          productRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });

        dispatch(add_to_cart({ _id: id, quantity: 1 }, success_callback, error_callback));
      }, timeoutScroll);
    }
    return () => {};
  }, [query, productRef, timeoutScroll]);

  useEffect(() => {
    let check = checkFavoriteProduct(userState?._id, favorite_users);
    setFavorite(isFavorite || check);
    return () => {};
  }, [userState]);

  return (
    <div className="SearchProduct-container" ref={productRef}>
      <BottomModal
        setOpen={setAddLocationModal}
        open={addLocationModal}
        product_id={id}
        route={history?.location?.pathname}
        query={query}
      />
      <div className="product-image">
        {
          <Image
            style={{ display: imageloading ? "none" : "block" }}
            src={imgSrc}
            onLoad={ImageLoaded}
          />
        }
        {imageloading && (
          <Placeholder>
            <Placeholder.Image style={{ width: 96, height: 96 }} />
          </Placeholder>
        )}
        {out_of_stock && !imageloading && (
          <div className="out-of-stock">
            <p>مباع</p>
          </div>
        )}
      </div>
      <div className="content">
        <div className="title">
          <p>{title}</p>
        </div>
        <div className="price">{price} دينار</div>
      </div>

      <div className="left">
        <div onClick={switchToFavorite} className="favorite">
          {favoriteloading ? (
            <Loader active size="small" inline="centered" />
          ) : (
            <Icon
              className={favorite ? "selected" : ""}
              size="large"
              name={favorite ? "heart" : "heart outline"}
            />
          )}
        </div>

        <div
          className="add-to-cart"
          style={addedToCart ? { borderColor: "#3cc08f" } : {}}
          onClick={addToCart}
        >
          {loading ? (
            <Loader active size="mini" inline="centered" style={{ width: 80 }} />
          ) : addedToCart ? (
            <p style={{ color: "#3cc08f" }}>تم إضافة المنتج للسلة </p>
          ) : (
            <p>
              <span>+</span>
              اضف للسلة
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchProduct;
