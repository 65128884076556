import React, { useState } from "react";
import "./index.scss";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Button, Message } from "semantic-ui-react";
import { PUT_REQ } from "../../helpers/requests";
import { validateForm } from "../../helpers/validation";
import useQueryString from "use-query-string";

const ResetPassword = ({ params }) => {
  const userState = useSelector((state) => state.UserReducer);
  const history = useHistory();

  const [query] = useQueryString(window.location);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });

  const onSubmit = async () => {
    if (formData && query?.code && !loading) {
      let validation = validateForm({ ...formData });

      if (formData?.password !== formData?.newpassword) {
        setErrors({ ...errors, password: "يجب ان تكون كلمة المرور متساوية" });
        return;
      }

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setLoading(true);
      let res = await PUT_REQ(`users/reset-password/${userState?.reset_token}`, {
        ...formData,
        code: query?.code,
      });

      if (res?.data?.status === "success") {
        setLoading(false);
        history.push("/passwordChangedSuccessfully");
      } else {
        setLoading(false);
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
      setLoading(false);
    }
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="reset-password-page">
      <p className="title">إعادة تعيين كلمة المرور</p>
      <p className="desc">يرجى إنشاء كلمة مرور جديدة وتأكيدها</p>

      <Form className="form" onSubmit={onSubmit} error={errors?.form?.error}>
        <Form.Field>
          <Form.Input
            required
            name="password"
            type="password"
            error={errors?.password ? { content: errors?.password, pointing: "below" } : false}
            onChange={handleChange}
            placeholder="كلمة المرور الجديدة"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            name="newpassword"
            type="password"
            onChange={handleChange}
            error={
              errors?.newpassword ? { content: errors?.newpassword, pointing: "below" } : false
            }
            placeholder="تأكيد كلمة المرور الجديدة"
          />
        </Form.Field>

        {errors?.form?.error && <Message error header="Error" content={errors?.form?.message} />}

        <Button className="secondary submit-button" type="submit" loading={loading}>
          تعيين كلمة المرور الجديدة
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
