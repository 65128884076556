import { GET_REQ, POST_REQ } from "../../helpers/requests";
import actionTypes from "../actionTypes";

import store from "../";

export const fetch_cart = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CART,
      payload: { loading: true },
    });

    let res = await GET_REQ(`cart`);

    if (res?.data?.status === "success") {
      if (res?.data?.data?.items?.length > 0 && res?.data?.data?.shipping_price) {
        let totalPrice = 0;

        totalPrice += res?.data?.data?.shipping_price;

        res.data.data.items.forEach(({ price, quantity }) => {
          totalPrice += price * quantity;
        });

        dispatch({
          type: actionTypes.FETCH_CART,
          payload: { ...res?.data?.data, totalPrice: totalPrice.toFixed(2), loading: false },
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_CART,
          payload: { ...res?.data?.data, totalPrice: (0).toFixed(2), loading: false },
        });
      }
    } else {
      dispatch({
        type: actionTypes.FETCH_CART,
        payload: { loading: false },
      });
    }
  };
};

export const update_cart = (payload) => {
  return async (dispatch) => {
    if (payload.items?.length > 0 && payload.shipping_price) {
      let totalPrice = 0;

      totalPrice += payload.shipping_price;

      payload.items.forEach(({ price, quantity }) => {
        totalPrice += price * quantity;
      });

      dispatch({
        type: actionTypes.FETCH_CART,
        payload: { ...payload, totalPrice: totalPrice.toFixed(2), loading: false },
      });
    } else {
      dispatch({
        type: actionTypes.FETCH_CART,
        payload: { ...payload, totalPrice: (0).toFixed(2), loading: false },
      });
    }
  };
};

export const add_to_favorite = async (productId, callback) => {
  if (productId) {
    let res = await POST_REQ(`/products/${productId}/favorite`);

    if (res?.data?.status === "success") {
      return true;
    }
  }

  return false;
};

export const add_to_cart = (payload, success_callback, error_callback) => {
  return async (dispatch) => {
    if (
      !store?.getState()?.CartReducer?.address &&
      (store?.getState()?.AddressesReducer?.selected_address ||
        store?.getState()?.AddressesReducer?.addresses)
    ) {
      await POST_REQ(`cart/address`, {
        address:
          store?.getState()?.AddressesReducer?.selected_address ||
          store?.getState()?.AddressesReducer?.addresses[0],
      });
    }

    let res = await POST_REQ("cart/add", { ...payload });

    if (res?.data?.status === "success") {
      success_callback && success_callback();
      dispatch(update_cart(res?.data?.data));
    } else {
      error_callback && error_callback();
    }
  };
};

export const clear_cart = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_CART,
    });
  };
};

export const clear_cart_without_address = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_CART_WITHOUT_ADDRESS,
    });
  };
};
