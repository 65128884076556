import React, { useEffect } from "react";
import "./App.scss";
import Routers from "./Routers";
import { useDispatch, useSelector } from "react-redux";

import history from "./history";
import { hot } from "react-hot-loader";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
import { Router, Switch } from "react-router";
import { fetch_user } from "./redux/actions/user";
import { fetch_cart } from "./redux/actions/cart";
import { checkIfUserOrGuestLoggedIn } from "./helpers/helper";
import { fetch_addresses } from "./redux/actions/addresses";
import { Modal } from "semantic-ui-react";

function App() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(fetch_addresses());
    if (userState?.loggedIn || checkIfUserOrGuestLoggedIn()) {
      dispatch(fetch_cart());
    }
    return () => {};
  }, [userState?.loggedIn]);

  useEffect(() => {
    if (userState?.loggedIn || checkIfUserOrGuestLoggedIn()) {
      dispatch(fetch_user());
    }
    return () => {};
  }, []);

  return (
    <Router history={history}>
      {/* <Modal
        open={true}
        className="modal-app-soon"
        size="large"
        dimmer="blurring"
        content="Coming Sooon"
      /> */}
      <Switch>
        <SideBar>
          <Routers />
        </SideBar>
      </Switch>
      <Footer />
    </Router>
  );
}

export default hot(module)(App);
