import { applyMiddleware, compose, createStore } from "redux";
import rootReducers from "./reducers";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { mobileCheck } from "../helpers/helper";

let is_mobile = mobileCheck();
let is_dev = process.env.NODE_ENV === "development";

let devMiddlewares = [...(is_dev ? [logger] : [])];
let middlewares = [thunk, ...devMiddlewares];

const store = createStore(
  rootReducers,

  is_dev
    ? !is_mobile
      ? window.__REDUX_DEVTOOLS_EXTENSION__
        ? compose(
            applyMiddleware(...middlewares),
            is_dev && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
          )
        : compose(applyMiddleware(...middlewares))
      : compose(applyMiddleware(...middlewares))
    : compose(applyMiddleware(...middlewares))
);
export default store;
