import React, { useState, useRef, useEffect } from "react";
import "./index.scss";

import { GoogleMap, withGoogleMap } from "react-google-maps";
import Polygon from "react-google-maps/lib/components/Polygon";
import OverlayView from "react-google-maps/lib/components/OverlayView";

const GMap = withGoogleMap(({ areas, onChange, userLocation }) => {
  const [finalAreas, setFinalAreas] = useState(null);

  const [defaultCenter, setDefaultCenter] = useState(
    userLocation?.lat ? { ...userLocation } : { ...areas[0]?.points[0] }
  );

  const refMap = useRef(null);

  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter();
    setDefaultCenter(mapCenter);

    if (mapCenter && areas) {
      let isInSupportedArea = areas?.some((area) => handleOverlayComplete(area, mapCenter));

      onChange && mapCenter && onChange(mapCenter, isInSupportedArea || false);
    }
  };

  const handleOverlayComplete = (area, location) => {
    if (area && location) {
      let bermudaTriangle = new window.google.maps.Polygon({
        paths: area?.points,
      });
      let containsPlace = window.google.maps.geometry.poly.containsLocation(
        location,
        bermudaTriangle
      );

      return containsPlace;
    } else {
      return false;
    }
  };

  let everythingElse = [
    new window.google.maps.LatLng(0, -90),
    new window.google.maps.LatLng(0, 90),
    new window.google.maps.LatLng(90, -90),
    new window.google.maps.LatLng(90, 90),
  ];

  const polygonArea = (vertices) => {
    // if area is coming from right to left ( clockwise ) it will work correctly
    // if it comes from left to right ( non clockwise ) will to work correctly
    // so all should be the same right to left ( clockwise ) or left to right ( non clockwise )

    var area = 0;
    for (var i = 0; i < vertices.length; i++) {
      let j = (i + 1) % vertices.length;
      area += vertices[i].lat * vertices[j].lng;
      area -= vertices[j].lat * vertices[i].lng;
    }
    return area / 2;
  };

  useEffect(() => {
    let finalAreas = areas?.map((area) => {
      let clockwise = polygonArea(area?.points) > 0;

      if (clockwise) {
        return area;
      } else {
        area.points.reverse();
        return area;
      }
    });
    setFinalAreas(finalAreas);

    return () => {};
  }, [areas]);

  return (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={defaultCenter}
      onBoundsChanged={handleBoundsChanged}
      defaultOptions={{
        streetViewControl: false,
        gestureHandling: "greedy",
        zoomControl: false,
        mapTypeControl: false,
      }}
      gestureHandling="none"
      ref={refMap}
    >
      <Polygon
        paths={[everythingElse, ...(finalAreas?.map(({ points }) => points) || [])]}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#d9e0ed",
          fillOpacity: 0.5,
        }}
      />

      <OverlayView
        key={Math.random()}
        position={defaultCenter}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className={`current-center-map-position`}>
          <div>مكان التوصيل</div>
        </div>
      </OverlayView>
    </GoogleMap>
  );
});
const Map = (props) => {
  return (
    <>
      {props?.areas?.length > 0 ? (
        <GMap
          key={JSON.stringify(props?.userLocation)}
          {...props}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCm7TROx-3ywRQqKJf4ziZgRB_JQacJmN0&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `calc(100% - 174px)` }} />}
          containerElement={<div style={{ height: `calc(100% - 174px)` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      ) : (
        <div style={{ height: `calc(100% - 174px)` }}></div>
      )}
    </>
  );
};

export default Map;
