import * as actionTypes from "../actionTypes";

const initialState = {
  loggedIn: false,
  token: ``,
  email: "",
  phone: "",
  username: "",
  _id: "",
};

// email	"atallahsalah992@gmail.com"
// password	"123456"
// phone	"0772233399"
// username	"atallah"

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        ...action.payload,
        loggedIn: action.payload ? true : false,
      };

    case actionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.LOGOUT_USER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default UserReducer;
