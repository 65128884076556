/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
let is_dev = process.env.NODE_ENV === "development";

export const validateForm = (formData) => {
  let errors = {};

  if (!formData) {
    return;
  }

  for (let key in formData) {
    try {
      let checkErrors = eval("validate" + key)(formData[key]);

      if (checkErrors) {
        errors[key] = checkErrors;
      }
    } catch (e) {
      is_dev && console.error("validation form error", e);
    }
  }

  if (errors && Object.keys(errors).length > 0) {
    return errors;
  } else {
    return false;
  }
};

const validatecoupon = (coupon) => {
  if (coupon) {
    if (coupon.length < 2) {
      return "الرمز غير صحيح";
    }
  } else {
    return "يرجى إدخال الرمز";
  }
};

const validateusername = (username) => {
  if (username) {
  } else {
    return "يرجى إدخال الإسم";
  }
};

const validatepassword = (password) => {
  if (password) {
    if (password.length < 6) {
      return "يجب ان تتكون كلمة المرور من 6 احرف او اكثر";
    }
  } else {
    return "يرجى إدخال كلمة المرور";
  }
};

const validatenewpassword = validatepassword;
const validateoldpassword = validatepassword;

const validatephone = (phone) => {
  if (phone) {
    if (phone.length < 10 || phone.length > 10) {
      return "يجب ان يتكون رقم الهاتف من 10 ارقام";
    }
  } else {
    return "يرجى إدخال رقم هاتف";
  }
};

const validateemail = (email) => {
  if (email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let checked = re.test(String(email).toLowerCase());

    if (!checked) {
      return "يرجى إدخال بريد صحيح";
    }
  } else {
    return "يرجى إدخال البريد";
  }
};
