import React, { useState } from "react";
import moment from "moment";
import { Accordion, Transition } from "semantic-ui-react";
import ProductRaw from "../ProductRaw";
import "./index.scss";

const OrderInsideCard = ({ orderData }) => {
  const [showDetails, setShowDetails] = useState(false);

  const { payment_details, shipping_price, items, createdAt, _id } = orderData || {};

  const clickShowDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="OrderInsideCard">
      <div className="header">
        <p className="title">طلب #{_id}</p>
        {!showDetails && (
          <div className="total-price">
            <p className="title">المبلغ الكلي</p>
            <p className="price">
              {(payment_details?.total_paid + shipping_price).toFixed(2)}
              <span> دينار</span>
            </p>
          </div>
        )}
      </div>

      <Accordion className="details-content">
        <Accordion.Title active={showDetails}>{""}</Accordion.Title>
        <Accordion.Content active={showDetails} className="expand">
          <Transition visible={showDetails} animation="fade down" duration={500}>
            <div>
              <p className="sub-title">المشتريات</p>
              <div className="content-data">
                {items?.map(({ price, quantity, title, size: { value } }) => (
                  <ProductRaw title={`${title}`} price={price.toFixed(2)} qty={quantity} />
                ))}
              </div>

              <p className="sub-title details">تفاصيل الطلب</p>

              <div className="order-details-bottom">
                <div className="raw">
                  <div className="right">تاريخ الطلب</div>
                  <div className="left date">{moment(createdAt).format("DD, MM, YYYY | h:mm")}</div>
                </div>
                <div className="raw">
                  <div className="right">قيمة التوصيل</div>
                  <div className="left sum-all" style={{ color: "#3cc08f" }}>
                    مجاناً
                  </div>
                </div>
                <div className="raw">
                  <div className="right">المبلغ الكلي</div>
                  <div className="left sum-all">
                    {(payment_details?.total_paid + shipping_price).toFixed(2)}
                    <span>دينار</span>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </Accordion.Content>
      </Accordion>

      <Accordion className="details">
        <Accordion.Title onClick={clickShowDetails}>
          <div className="content">تفاصيل الطلب</div>
        </Accordion.Title>
      </Accordion>
    </div>
  );
};

export default OrderInsideCard;
