import * as React from "react";

function HomeSvg(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{"2B1BFBC5-86E3-4DBD-A486-43E92B71DC39@1"}</title>
      <path
        d="M22.387 10.569l-.002-.002-8.957-8.974A2.006 2.006 0 0012 1c-.54 0-1.048.21-1.43.593l-8.952 8.97-.009.009a2.03 2.03 0 00.004 2.86 2.008 2.008 0 001.404.594h.357v6.604A2.37 2.37 0 005.739 23h3.504a.644.644 0 00.643-.645v-5.177c0-.597.484-1.082 1.08-1.082h2.066c.596 0 1.08.485 1.08 1.082v5.177c0 .356.288.645.643.645h3.504a2.37 2.37 0 002.366-2.37v-6.604h.331c.54 0 1.047-.211 1.43-.594.787-.79.787-2.073 0-2.863h0zm-.911 1.952a.73.73 0 01-.52.216h-.974a.644.644 0 00-.643.644v7.249c0 .596-.485 1.08-1.08 1.08H15.4v-4.532a2.37 2.37 0 00-2.367-2.37h-2.066a2.371 2.371 0 00-2.366 2.37v4.533H5.739c-.595 0-1.08-.485-1.08-1.081V13.38a.644.644 0 00-.643-.644h-.958a.729.729 0 01-.536-.216.738.738 0 010-1.042s0 0 0 0h.001l8.957-8.974a.728.728 0 01.519-.216c.196 0 .38.077.52.216l8.954 8.971.004.005a.739.739 0 01-.001 1.04z"
        fill={props?.stroke || "#FFF"}
        fillRule="nonzero"
        stroke={props?.stroke || "#FFF"}
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default HomeSvg;
