module.exports = {
  // user
  LOGOUT_USER: "LOGOUT_USER",
  LOGIN_USER: "LOGIN_USER",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",

  // search
  START_SEARCH: "START_SEARCH",
  CLEAR_SEARCH: "CLEAR_SEARCH",
  DONE_SEARCH: "DONE_SEARCH",

  // CART
  FETCH_CART: "FETCH_CART",
  UPDATE_CART: "UPDATE_CART",
  CLEAR_CART: "CLEAR_CART",
  CLEAR_CART_WITHOUT_ADDRESS: "CLEAR_CART_WITHOUT_ADDRESS",

  // ADDRESSES
  FETCH_ADDRESSES: "FETCH_ADDRESSES",
};
