import React, { useState, useEffect } from "react";
import { GET_REQ } from "../../helpers/requests";
import OrderCardPlaceHolder from "../../PlaceHolders/OrderCardPlaceHolder";
import OrderCard from "../OrderCard";
import "./index.scss";

const MyOrders = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);

    let res = await GET_REQ("orders", {
      fields: "_id,payment_details,createdAt,shipment_details",
    });

    if (res?.data?.status === "success") {
      setData(res?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="MyOrders-page">
      <p className="title">طلباتي</p>
      <div className="content">
        {loading ? (
          <>
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
          </>
        ) : (
          <>
            {data?.length > 0 ? (
              data?.map(({ _id, payment_details, created_at, shipment_details }) => (
                <OrderCard
                  id={_id}
                  price={
                    payment_details?.total_paid +
                    ((shipment_details && shipment_details[0]?.amount) || 0)
                  }
                  date={created_at}
                />
              ))
            ) : (
              <p>لا يوجد لديك طلبات حاليا</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
