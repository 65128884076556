import * as actionTypes from "../actionTypes";

const initialState = {
  _id: "",
  totalPrice: "0.00",
  loading: false,
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CART:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_CART:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.CLEAR_CART:
      return {
        ...initialState,
      };

    case actionTypes.CLEAR_CART_WITHOUT_ADDRESS:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default CartReducer;
