import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Image, Placeholder } from "semantic-ui-react";
import { useHistory } from "react-router";

const SmallCategory = ({ id, title, imgSrc, onClick, selected }) => {
  const [imageloading, setImageLoading] = useState(true);
  const history = useHistory();
  const categoryRef = useRef();

  const onClickButton = () => {
    history.push(`/Category/${id}`);
    !selected && onClick && onClick(id);
  };

  const ImageLoaded = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    if (selected) {
      categoryRef?.current &&
        categoryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    }

    return () => {};
  }, [selected]);

  return (
    <div
      ref={categoryRef}
      id={id}
      className={["category-small", selected ? "selected" : ""].join(" ")}
      onClick={onClickButton}
    >
      <Image
        className="image"
        src={imgSrc}
        onLoad={ImageLoaded}
        style={{ display: imageloading ? "none" : "block" }}
      />
      {imageloading && (
        <Placeholder>
          <Placeholder.Image style={{ height: 64 }} />
        </Placeholder>
      )}
      <p className="title">{title}</p>
    </div>
  );
};

export default SmallCategory;
