import React from "react";
import "./index.scss";

const TermsandConditions = (props) => {
  return (
    <div className="TermsandConditions-page">
      <div>
        <p style={{ marginBottom: "12.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Terms and Conditions for <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black", background: "white" }}>Here’s everything you need to know about our Terms of Service.</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Welcome to our Website (Labeeba) This Website is published by or on behalf of <span>Labeeba&nbsp;</span>
          </span>
          <span style={{ fontSize: "16px" }}>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            , a company registered in Jordan, under company number ( &nbsp;) whose registered office is in Amman, Jordan.
            <span style={{ background: "white" }}>
              <br />
              &nbsp;
              <br />
              &nbsp;
            </span>
          </span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black", background: "white" }}>
              Please read them carefully as they contain important information about your legal rights and obligations. By accessing or using Labeeba’s Platform , you agree to comply with and be bound
              by these Terms of service.
              <br />
              &nbsp;
            </span>
          </strong>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black", background: "white" }}>
            <br />
            &nbsp;These Terms of Service ("Terms") constitute a legally binding agreement ("Agreement") between you and Labeeba (as defined below) governing your access to and use of the Labeeba
            platform, including any subdomains thereof, and any other Websites through which Labeeba makes its services available (collectively, "Websites"), our mobile, and other smart device
            Websites.
            <br />
            &nbsp;
            <br />
            &nbsp;Our collection and use of personal information in connection with your access to and use of the Labeeba Platform is described in our Privacy Policy.
            <br />
            &nbsp;
            <br />
            &nbsp;
          </span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Introduction</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            These Website’s Standard Terms and Conditions written on this Website shall manage your use of our Website, Labeeba accessible at ().
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            These Terms will be Websitelied fully and affect to your use of this Website. By using Labeeba mobile Website, you agreed to accept all terms and conditions written in here. You must not
            use this Website if you disagree with any of these Website Standard Terms and Conditions.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Intellectual Property Rights</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Other than the content you own, under these Terms, Titanic Digital Marketing LLC and/or its licensors own all the intellectual property rights and materials contained in this Website.
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            You are granted limited license only for purposes of viewing the material contained on this Website.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            The trademarks, service marks, and logos (“Trademarks“) contained on or in the Website are owned by Labeeba or its group companies or third party partners of Labeeba. You cannot use, copy,
            edit, vary, reproduce, publish, display, distribute, store, transmit, commercially exploit or disseminate the Trade Marks without the prior written consent of Labeeba or the relevant group
            company or the relevant third party partner of Labeeba.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>General Rules Relating To Conduct</span>
          </strong>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Labeeba platform is made available for your own, personal use. The platform must not be used for any commercial purpose whatsoever or for any illegal or unauthorised purpose. When you use
            the platform you must comply with all Website applicable country laws and with any Website applicable international laws, including the local laws in your country of residence (together
            referred to as “Website applicable Laws”).
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Restrictions</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>You are specifically restricted from all of the following:</span>
        </p>
        <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>publishing any Website material in any other media;</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>selling, sublicensing and/or otherwise commercializing any Website material;</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>publicly performing and/or showing any Website material;</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>using this Website in any way that is or may be damaging to this Website/Website;</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>using this Website/ Website in any way that impacts user access to this Website/Website;</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              using this Website contrary to Website applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>using this Website to engage in any advertising or marketing.</span>
          </li>
        </ul>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Certain areas of this Website are restricted from being access by you and <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;LLC may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and
            you must maintain confidentiality as well.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Your Content</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content,
            you grant <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;LLC a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Your Content must be your own and must not be invading any third-party’s rights. <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;LLC reserves the right to remove any of Your Content from this Website at any time without notice.
          </span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Link to Third Parties</span>
          </strong>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            The Website may contain links to Websites operated by third parties (“Third Party Websites/Website“). Labeeba may monetise some of these links through the use of third party affiliate
            programmes. Notwithstanding such affiliate programmes, Labeeba does not have any influence or control over any such Third Party Websites and, unless otherwise stated, is not responsible
            for and does not endorse any Third Party Websites or their availability or contents.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>No warranties</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            This Website is provided "as is," with all faults, and <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted
            as advising you.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Limitation of liability</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            In no event shall <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            , nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under
            contract. &nbsp;<span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            , including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this
            Website.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Disclaimer / Liability</span>
          </strong>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Use of the Website is at your own risk. The Website is provided on an “as is” basis. To the maximum extent permitted by law: (a) Labeeba disclaims all liability whatsoever, whether arising
            in contract, tort (including negligence) or otherwise in relation to the Website; and (b) all implied warranties, terms and conditions relating to the Website (whether implied by statue,
            common law or otherwise), including (without limitation) any warranty, term or condition as to accuracy, completeness, satisfactory quality, performance, fitness for purpose or any special
            purpose, availability, non infringement, information accuracy, interoperability, quiet enjoyment and title are, as between Labeeba and you, hereby excluded. In particular, but without
            prejudice to the foregoing, we accept no responsibility for any technical failure of the internet and/or the Website; or any damage or injury to users or their equipment as a result of or
            relating to their use of the Website. Your statutory rights are not affected.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Labeeba will not be liable, in contract, tort (including, without limitation, negligence), under statute or otherwise, as a result of or in connection with the Website, for any: (i)
            economic loss (including, without limitation, loss of revenues, profits, contracts, business or anticipated savings); or (ii) loss of goodwill or reputation; or (iii) special or indirect
            or consequential loss.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            If Labeeba is liable to you directly or indirectly in relation to the Website, that liability shall be limited to: the sums paid by you upon purchasing the Website, or any in-Website
            spend, including subscriptions, whichever is greater.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Nothing in these Terms shall be construed as excluding or limiting the liability of Labeeba or its group companies for death or personal injury caused by its negligence or for any other
            liability which cannot be excluded by English law.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Labeeba Privacy Policy</span>
          </strong>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            We take your privacy very seriously. Labeeba will only use your personal information in accordance with the terms of our privacy policy and cookies policy. By using the Website you
            acknowledge and agree that you have read and accept the terms of our Website privacy policy and these terms.
          </span>
        </p>
        <p style={{ marginBottom: "12.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>
            <br />
            &nbsp;
            <br />
            &nbsp;
          </span>
        </p>
        <p style={{ marginTop: "19.0pt", marginRight: "0in", marginBottom: "11.0pt", marginLeft: "0in", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Eligibility, Using the Labeeba Platform, Member Verification</span>
          </strong>
        </p>
        <p style={{ marginBottom: "11.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black", background: "white" }}>
            You must be at least 18 years old and able to enter into legally binding contracts to access and use the Labeeba Platform or register a Labeeba Account. By accessing or using the Labeeba
            Platform, you warrant that you are 18 or older and have the legal capacity and authority to enter into a contract. Minors under the age of 18 shall are prohibited to register as a User of
            this Website/Website and are not allowed to transact or use the Website/Website.
          </span>
        </p>
        <p style={{ marginBottom: "11.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black", background: "white" }}>
            If you access or download the Labeeba Website from the &nbsp;Website Store, you agree to&nbsp;
          </span>
          <a href="http://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/">
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "#4A86E8", background: "white" }}>Website’s Licensed Website End User License Agreement</span>
          </a>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black", background: "white" }}>
            . Some areas of the Labeeba Platform implement Google Maps/Earth Website services, including Google Maps API(s). Your use of Google Maps/Earth is subject to the&nbsp;
          </span>
          <a href="https://www.google.com/help/terms_maps.html">
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "#4A86E8", background: "white" }}>Google Maps/Google Earth Additional Terms of Service</span>
          </a>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>.</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Payment Terms</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Any and all payment processing services through or in connection with your use of the Labeeba Platform ("Payment Services") are provided to you by one or more Labeeba Payments entities
            (individually and collectively, as appropriate, ("Labeeba Payments")
          </span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Any dispute or claim arising out of or in connection with this Website shall be governed and construed in accordance with the laws of Jordan.
          </span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Jordan is our country of domicile.&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            If you make a payment for our products or services on our Website, the details you are asked to submit will be provided directly to our payment provider via a secured connection. The
            cardholder must retain a copy of transaction records and Merchant policies and rules
          </span>
          <a href="#_msocom_1" id="_anchor_1" language="JavaScript" name="_msoanchor_1">
            [1]
          </a>{" "}
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>.</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Cancellation Policy</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Cancellation of purchased experiences are subject to the timeline given by each vendor based on their preference. However, &nbsp;a minimum of 48 hours prior to experience as per Labeeba’s
            guidelines, to allow vendors to settle their inventory. For any disputes, please email&nbsp;
          </span>
          <a href="mailto:contact@viavii.com">
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "#1155CC" }}>()</span>
          </a>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>&nbsp;to handle the issue directly with the service provider.</span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Indemnification</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            You hereby indemnify to the fullest extent <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Service Suspension&nbsp;</span>
          </strong>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;Labeeba reserves the right to suspend or cease providing any services relating to the Websites published by it, with or without notice, and shall have no liability or responsibility
            to you in any manner whatsoever if it chooses to do so.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Advertisers in the Website</span>
          </strong>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;We accept no responsibility for adverts contained within the Website. If you agree to purchase goods and/or services from any third party who advertises in the Website, you do so at
            your own risk. The advertiser, not Labeeba, is responsible for such goods and/or services and if you have any queries or complaints in relation to them, your only recourse is against the
            advertiser.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Competitions&nbsp;</span>
          </strong>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            If you take part in any competition which is run in or through the Website (“Competition“), you agree to be bound by the rules of that competition and any other rules specified by Labeeba
            from time to time (“Competition Rules“) and by the decisions of Labeeba, which are final in all matters relating to the Competition. Labeeba reserves the right to disqualify any entrant
            and/or winner in its absolute discretion without notice in accordance with the Competition Rules.
          </span>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>In-Website Voucher Codes</span>
          </strong>
        </p>
        <p style={{ marginBottom: "8.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Any in-Website voucher codes issued by Labeeba may only be used in accordance with our Terms and Conditions for in-Website voucher codes.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Severability</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            If any provision of these Terms is found to be invalid under any Website applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Variation of Terms</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Labeeba&nbsp;</span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Assignment</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Labeeba&nbsp;</span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or
            subcontract any of your rights and/or obligations under these Terms.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Entire Agreement</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            These Terms constitute the entire agreement between <span>Labeeba&nbsp;</span>
          </span>
          <span>Marketing Solutions</span>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;and you in relation to your use of this Website, and supersede all prior agreements and understandings.
          </span>
        </p>
        <p style={{ marginBottom: "14.0pt", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>Governing Law &amp; Jurisdiction</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Arial",sans-serif', color: "black" }}>
            These Terms will be governed by and interpreted in accordance with the laws of the country Jordan, and you submit to the non-exclusive jurisdiction of the state and federal courts located
            in Jordan for the resolution of any disputes.
          </span>
        </p>
        <p style={{ marginBottom: "0in", lineHeight: "normal" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "11.0pt", lineHeight: "normal", background: "white" }}>
          <span style={{ fontSize: "16px", fontFamily: '"Times New Roman",serif', color: "black" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "7.5pt", background: "white" }}>
          <strong>
            <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>General Terms</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>Revisions to the Terms</span>
        </p>
        <p style={{ marginBottom: "7.5pt", textAlign: "justify", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>
            We reserve the right to revise the Terms at our sole discretion at any time. Any revisions to the Terms will be effective immediately upon posting by us. For any material changes to the
            Terms, we will take reasonable steps to notify you of such changes. In all cases, your continued use of the Website after publication of such changes, with or without notification,
            constitutes binding acceptance of the revised Terms.
          </span>
        </p>
        <p style={{ marginBottom: "0in", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>Severability; Waiver</span>
        </p>
        <p style={{ marginBottom: "7.5pt", textAlign: "justify", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>
            If it turns out that a particular provision of these Terms is not enforceable, this will not affect any other Terms. If you do not comply with these Terms, and we do not take immediate
            action, this does not indicate that we relinquish any rights that we may have (such as taking action in the future).
          </span>
        </p>
        <p style={{ marginBottom: "7.5pt", textAlign: "justify", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>
            We want to make sure that all of our users and instructors feel safe and comfortable while using our Website. We have drafted these guidelines to ensure that people understand and follow
            the rules when participating in our online community and otherwise using our Website/Website and services.
          </span>
        </p>
        <p style={{ marginBottom: "7.5pt", textAlign: "justify", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>
            Although we do not routinely screen or monitor content provided by users, we may remove or edit in Website appropriate content or activity reported to us or suspend, disable, or terminate
            a user's access to all or part of the Website/Website.
          </span>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>You are prohibited from using our Website/Website to share content that:</span>
        </p>
        <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Contains illegal content or promotes illegal activities with the intent to commit such activities. Please keep in mind that users who are as young as 18 use the Website, and we do not
              allow content that is in Website appropriate for these younger learners.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Contains credible threats or organizes acts of real-world violence. We don’t allow content that creates a genuine risk of physical injury or property damage, credibly threatens people or
              public safety, or organizes or encourages harm.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              We encourage commentary about people and matters of public interest, but abusive or otherwise in Website appropriate content directed at private individuals is not allowed.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Violates intellectual property, privacy, or other rights. Do not share content that you do not have the right to share, claim content that you did not create as your own, or otherwise
              infringe or misappropriate &nbsp;someone else’s intellectual property or other rights. Always attribute materials used or quoted by you to the original copyright owner.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Spams others. Do not share irrelevant or inappropriate advertising, promotional, or solicitation content.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Otherwise violates theses Terms. Please note that specific services may have additional rules and requirements.</span>
          </li>
        </ul>
        <p style={{ marginBottom: "0in", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>You also aren't allowed to:</span>
        </p>
        <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Do anything that violates local, national or international law.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Share your password, let anyone access your account, or do anything that might put your account at risk.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Attempt to access any other user's account.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Reproduce, transfer, sell, resell, or otherwise misuse any content from our Website, unless specifically authorized to do so.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Access, tamper with, or use non-public areas of our systems, unless specifically authorized to do so.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Break or circumvent our authentication or security measures or otherwise test the vulnerability of our systems or networks, unless specifically authorized to do so.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Try to interfere with any user, host, or network, for example by sending a virus, overloading, spamming, or mail-bombing.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Use our Website/Website to distribute malware.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Impersonate or misrepresent your affiliation with any person or entity.</span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>Encourage or help anyone do any of the things on this list.</span>
          </li>
        </ul>
        <p style={{ marginBottom: "7.5pt", background: "white" }}>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ fontSize: "16px", lineHeight: "115%", fontFamily: '"Arial",sans-serif', color: "black" }}>&nbsp;</span>
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default TermsandConditions;
