import React from "react";
import "./index.scss";
import { Placeholder } from "semantic-ui-react";

const SmallCategoryPlaceholder = () => {
  return (
    <Placeholder className="SmallCategoryPlaceholder">
      <Placeholder.Image />
    </Placeholder>
  );
};

export default SmallCategoryPlaceholder;
