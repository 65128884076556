import React from "react";
import "./index.scss";

const Suggestions = () => {
  return (
    <div className="fast-access">
      <div className="title">
        <p>تصفح سريع</p>
      </div>
      <div className="suggestions">
        {/* <div className="item" onClick={() => searchSuggestion("بندورة")}> */}
        <div className="item" onClick={() => {}}>
          <p>بندورة</p>
        </div>
        <div className="item">
          <p>زيت زيتون</p>
        </div>
        <div className="item">
          <p>شيبس</p>
        </div>
        <div className="item">
          <p>بيبسي</p>
        </div>
        <div className="item">
          <p>معكرونة</p>
        </div>
        <div className="item">
          <p>حليب</p>
        </div>
        <div className="item">
          <p>لبن</p>
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
