import React, { useEffect, useState } from "react";
import "./index.scss";
import Banner from "../../components/Banner";
import ChooseLocation from "../../components/ChooseLocation";
import Categories from "../../components/Categories";
import ProductsGroup from "../../components/ProductsGroup";
import { GET_REQ } from "../../helpers/requests";

const Home = (props) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  const getData = async () => {
    setLoading(true);

    let res = await GET_REQ(`categories`, {
      get_products: true,
      limit: 2,
      sort: "sort",
      products_limit: 10,
    });

    if (res?.data?.status === "success") {
      setCategories(res?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="home-page">
      <Banner imageSrc={"assets/icons/labeba_banner.svg"} title={`طلبك واصل لعندك في 10 دقائق!`} />

      <ChooseLocation time={10} />

      <Categories />

      {loading ? (
        <>
          <ProductsGroup loading={loading} title={``} />
          <ProductsGroup loading={loading} title={``} />
        </>
      ) : (
        <>
          {categories?.map(({ name_ar, products, _id }) => (
            <ProductsGroup
              key={_id}
              loading={loading}
              title={name_ar}
              products={products}
              _id={_id}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
