import * as React from "react";

function SearchSvg(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{"16FD0B5F-83E6-4AAC-885D-1772CB8640B9@1"}</title>
      <path
        d="M21.73 20.438l-5.288-5.29a9.239 9.239 0 002.1-5.877A9.271 9.271 0 000 9.271a9.271 9.271 0 009.272 9.272 9.223 9.223 0 005.873-2.097l5.29 5.285a.916.916 0 001.297-1.293zm-12.459-3.74c-4.099 0-7.43-3.332-7.43-7.427 0-4.094 3.331-7.43 7.43-7.43 4.095 0 7.431 3.336 7.431 7.43 0 4.095-3.336 7.427-7.43 7.427z"
        fill={props?.stroke || "#FFF"}
        fillRule="nonzero"
        stroke={props?.stroke || "#FFF"}
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default SearchSvg;
