import React from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import useQueryString from "use-query-string";

const PasswordChangedSuccessfully = () => {
  const history = useHistory();
  const [query] = useQueryString(window.location);

  const goToLoginPage = () => {
    if (query?.m === "change") {
      history.push("/");
    } else {
      history.push("/login");
    }
  };

  return (
    <div className="password-changed-successfully-page">
      <div className="content">
        <img className="success-reset-password" alt="" src="/assets/icons/change_success.svg" />
        <p className="title">تم إعادة تعيين كلمة المرور الجديدة بنجاح</p>
        <Button onClick={goToLoginPage} className="secondary submit-button">
          {query?.m === "change" ? "العودة للرئيسية" : "تسجيل الدخول"}
        </Button>
      </div>
    </div>
  );
};

export default PasswordChangedSuccessfully;
