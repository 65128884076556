import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.scss";
import { Form, Button, Input, Icon, Message } from "semantic-ui-react";
import { POST_REQ } from "../../helpers/requests";
import { login } from "../../redux/actions/user";
import useQueryString from "use-query-string";
import { validateForm } from "../../helpers/validation";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query] = useQueryString(window.location);

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(null);

  const goToSignupPage = () => {
    if (query.r === "cart") {
      history.push("/signup?r=cart");
    } else {
      history.push("/signup");
    }
  };

  const goToForgetPasswordPage = () => {
    history.push("/forgetPassword");
  };

  const triggerShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    if (formData && !loading) {
      let validation = validateForm({ ...formData });

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setLoading(true);
      let res = await POST_REQ(`users/login`, { ...formData });
      setLoading(false);

      if (res?.data?.status === "success") {
        dispatch(login(res?.data?.data));
        if (query.r === "cart") {
          history.push("/mycart");
        } else {
          history.push("/");
        }
      } else {
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
    }
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="login-page">
      <p className="title">تسجيل الدخول</p>
      <p className="desc">وفر وقتك وجهدك ... ادخل حسابك ... تسوق بلا حدود</p>

      <Form className="form" onSubmit={onSubmit} error={errors?.form?.error}>
        <Form.Field>
          <Form.Input
            name="phone"
            required
            error={errors?.phone ? { content: errors?.phone, pointing: "below" } : false}
            onChange={handleChange}
            placeholder="0790000000"
            className="phone-number-input"
            maxLength="10"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            name="password"
            error={errors?.password ? { content: errors?.password, pointing: "below" } : false}
            type={showPassword ? "text" : "password"}
            className="password-input"
            placeholder="كلمة المرور"
            iconPosition="left"
            onChange={handleChange}
          >
            {showPassword ? <Icon onClick={triggerShowPassword} color="#253B69" name="eye" /> : <Icon onClick={triggerShowPassword} color="#253B69" name="eye slash" />}
            <input required />
          </Form.Input>
          <div className="forget-password">
            <p className="forget-password-text" onClick={goToForgetPasswordPage}>
              هل نسيت كلمة المرور؟
            </p>
          </div>
        </Form.Field>
        {errors?.form?.error && <Message error header="Error" content={errors?.form?.message} />}

        <Button className="secondary submit-button" type="submit" loading={loading}>
          تسجيل الدخول
        </Button>
        <p className="login-no-account">
          لا يوجد لديك حساب؟<span onClick={goToSignupPage}>انشاء حساب</span>
        </p>
      </Form>
    </div>
  );
};

export default Login;
