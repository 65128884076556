import React, { useState, useEffect } from "react";
import "./index.scss";
import Map from "../../components/Map";
import { GET_REQ, POST_REQ } from "../../helpers/requests";
import { Button, Placeholder } from "semantic-ui-react";
import { getAddressDetails, getUserLocationWithPermission } from "../../helpers/helper";
import { getAddressInfo } from "../../redux/actions/googleAddress-action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { update_cart } from "./../../redux/actions/cart";
import useQueryString from "use-query-string";
import { fetch_addresses } from "../../redux/actions/addresses";

let timeOut;

const ChooseLocationMap = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query] = useQueryString(window.location);

  const [areas, setAreas] = useState(null);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [supportedArea, setSupportedArea] = useState(false);
  const [AddressLocation, setAddressLocation] = useState(false);
  const [addressText, setAddressText] = useState("العنوان");
  const [userLocation, setUserLocation] = useState(null);
  const [mapError, setMapError] = useState(null);

  const getDate = async () => {
    let res = await GET_REQ(`areas`);

    if (res?.data?.status === "success") {
      setAreas(res?.data?.data);
    }
  };

  const getLocationInformation = async (location) => {
    setLoading(true);

    let { lat, lng } = {
      lat: location.lat(),
      lng: location.lng(),
    };

    dispatch(
      getAddressInfo(lat, lng, (res) => {
        if (res) {
          setLoading(false);
          let data = getAddressDetails(lat, lng, res);
          setAddressText(data?.formatted_address);
          setAddressLocation(data);
        }
      })
    );
  };

  const onChnageMarker = (location, isInSupportedArea) => {
    setLoading(true);

    timeOut && clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      getLocationInformation(location);
    }, 800);

    if (isInSupportedArea) {
      setSupportedArea(isInSupportedArea);
    } else {
      setSupportedArea(false);
    }
  };

  const addAddressLocation = async () => {
    if (!loading && supportedArea && AddressLocation) {
      setPostLoading(true);

      let res = await POST_REQ(`cart/address`, { address: AddressLocation });

      if (res?.data?.status === "success") {
        setPostLoading(false);
        dispatch(update_cart(res?.data?.data));
        dispatch(fetch_addresses());

        // check if user coming from cart page
        if (query?.q === "cart") {
          history.push("/mycart");
        } else {
          // check for pid (product id) in query
          if (query?.pid) {
            // check if r (route) is in query
            if (query?.r && query?.r !== "/") {
              // go to that route page and navigate (scroll) to that product
              history.push(`${query?.r}/?pid=${query?.pid}`);
            } else {
              if (query?.q) {
                // go to search page and place search string and scroll to that product
                history.push(`/search?q=${query?.q}&pid=${query?.pid}`);
              } else {
                // go to home page and navigate (scroll) to that product
                history.push(`/?pid=${query?.pid}`);
              }
            }
          } else {
            history.push("/");
          }
        }
      } else {
        setPostLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserLocationWithPermission(
      (location) => {
        if (location?.coords) {
          setUserLocation({ lat: location?.coords?.latitude, lng: location?.coords?.longitude });
          setMapError(false);
        }
      },
      (error) => {
        setMapError(true);

        console.error(error);
      }
    );
    getDate();

    return () => {};
  }, []);

  return (
    <div className="ChooseLocationMap-page">
      <Map areas={areas} onChange={onChnageMarker} userLocation={userLocation} />
      <div className="footer">
        {mapError && (
          <p className="map-error">يرجى إعطاء صلاحية الموقع الجغرافي لنتمكن من تحديد موقعك</p>
        )}
        <div className="location">
          <div className="content">
            <img className="icon" alt="" src="/assets/icons/location_white.svg" />
            {loading ? (
              <Placeholder style={{ height: 20, width: 120, marginRight: 8, borderRadius: 4 }}>
                <Placeholder.Image />
              </Placeholder>
            ) : (
              <p className="title">{addressText}</p>
            )}
          </div>
        </div>

        <div className="button-container">
          <Button
            onClick={addAddressLocation}
            className={["primary", supportedArea ? "success" : "faild"].join(" ")}
            loading={postLoading || loading}
          >
            {supportedArea ? "اختر العنوان" : "سيتم خدمة هذه المنطقة قريباً"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseLocationMap;
