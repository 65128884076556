import React, { useState, useEffect } from "react";
import "./index.scss";
import { Form, Icon } from "semantic-ui-react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { start_search } from "../../redux/actions/search";
import useQueryString from "use-query-string";

const NavBar = ({ color, menuVisible, setMenuVisible }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const SearchState = useSelector((state) => state.SearchReducer);
  const [query] = useQueryString(window.location);

  // const [cartItemsCount, setCartItemsCount] = useState(2);
  const [showSearch, setShowSearch] = useState(false);
  const [hideShadow, setHideShadow] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchDone, setSearchDone] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (history?.location?.pathname === "/search") {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }

    if (history?.location?.pathname === "/") {
      setHideShadow(true);
    } else {
      setHideShadow(false);
    }

    return () => {};
  }, [history?.location?.pathname]);

  const goToHomePage = () => {
    history.push("/");
  };

  const handleSearchChange = (event, { value }) => {
    setSearchValue(value);
    setSearchDone(false);
  };

  const getSearchResult = () => {
    if (searchValue) {
      dispatch(start_search(searchValue));
    }
  };

  const clearSearch = () => {
    setSearchDone(false);
    setSearchValue("");
  };

  useEffect(() => {
    if (SearchState.loading) {
      setLoading(true);
    } else {
      if (searchValue) {
        setSearchDone(true);
      }
      setLoading(false);
    }

    // if values comes from search page ( suggestions )
    if (SearchState.value) {
      setSearchValue(SearchState.value);
    }

    return () => {};
  }, [SearchState]);

  useEffect(() => {
    if (query?.q) {
      setSearchValue(query?.q);

      setSearchDone(true);
    }
    return () => {};
  }, [query?.q]);

  return (
    <div
      className={[
        "navbar-container",
        showSearch ? "search" : "",
        hideShadow ? "" : "show-shadow",
      ].join(" ")}
    >
      <div className="header">
        <div className="right">
          <img
            className="menu-icon"
            onClick={() => setMenuVisible(!menuVisible)}
            alt=""
            src={showSearch ? "/assets/icons/Menu_white.svg" : "/assets/icons/Menu_colored.svg"}
          />
        </div>

        <div className="center">
          {showSearch ? (
            <>
              <p className="search-title">ابحث عن المنتج الذي تريده</p>
            </>
          ) : (
            <img
              className="logo"
              onClick={goToHomePage}
              alt=""
              src="/assets/icons/logo.png"
              style={{ width: 220 }}
            />
          )}
        </div>

        <div className="left">
          {/* <div className="my-list-button" onClick={() => history.push("/myCart")}>
            <img alt="" src="/assets/icons/myCart.svg" />
            {cartItemsCount > 0 && <div className="my-cart-items-number">{cartItemsCount}</div>}
          </div> */}
        </div>
      </div>
      <div className="bottom">
        {showSearch && (
          <Form onSubmit={getSearchResult}>
            <Form.Input
              loading={loading}
              disabled={loading}
              name="search-value"
              icon={
                searchDone && searchValue ? (
                  <Icon onClick={clearSearch} name="close" link />
                ) : (
                  <Icon onClick={getSearchResult} name="search" link />
                )
              }
              placeholder={"حليب ، طماطم ، مناديل…"}
              onChange={handleSearchChange}
              className="search-input"
              value={searchValue}
              // value={this.state.query}
              // onChange={this.handleInputChange}
            />
            {/* <Search
          /> */}
          </Form>
        )}
      </div>
    </div>
  );
};

export default NavBar;
