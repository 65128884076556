import React, { useState } from "react";
import "./index.scss";

import { Form, Button } from "semantic-ui-react";
import { useHistory } from "react-router";
import { POST_REQ } from "../../helpers/requests";
import { validateForm } from "../../helpers/validation";
import { useDispatch } from "react-redux";
import { update_user_data } from "../../redux/actions/user";

const ForgetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(null);

  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });

  const goToLoginPage = () => {
    history.push("/login");
  };

  const onSubmit = async () => {
    if (formData && !loading) {
      let validation = validateForm({ ...formData });

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setLoading(true);
      let res = await POST_REQ(`users/forget-password`, { ...formData });

      if (res?.data?.status === "success") {
        dispatch(update_user_data({ phone: formData?.phone, reset_token: res?.data?.data }));
        setLoading(false);
        history.push("/verifyphone?reset-password");
      } else {
        setLoading(false);
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
      setLoading(false);
    }
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="forget-password-page">
      <p className="title">هل نسيت كلمة المرور؟</p>
      <p className="desc">
        ادخل رقم هاتفك وسوف نرسل لك كود مكون من اربع خانات لتعيين كلمة مرور جديدة
      </p>

      <Form onSubmit={onSubmit} className="form" error={errors?.form?.error}>
        <Form.Field>
          <Form.Input
            required
            error={errors?.phone ? { content: errors?.phone, pointing: "below" } : false}
            type="number"
            maxLength="10"
            onChange={handleChange}
            name="phone"
            placeholder="رقم الهاتف"
          />
        </Form.Field>
        <Button loading={loading} className="secondary submit-button" type="submit">
          ارسال
        </Button>
        <div className="bottom">
          <p className="remmberd-password" onClick={goToLoginPage}>
            تذكرت كلمة المرور الخاصة بي
          </p>
        </div>
      </Form>
    </div>
  );
};

export default ForgetPassword;
