import React from "react";
import { Redirect, Route } from "react-router-dom";
import Home from "./pages/Home";
import Search from "./pages/Search";
import MyCart from "./pages/MyCart";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import PasswordChangedSuccessfully from "./pages/PasswordChangedSuccessfully";
import VerifyPhone from "./pages/VerifyPhone";
import Categories from "./pages/Categories";
import AcconutCreatedSuccessfully from "./pages/AcconutCreatedSuccessfully";
import Profile from "./pages/Profile";
import MyOrders from "./pages/MyOrders";
import Order from "./pages/Order";
import { useSelector } from "react-redux";
import ChooseLocationMap from "./pages/ChooseLocationMap";
import Favorite from "./pages/Favorite";
import CategoryPage from "./pages/CategoryPage";
import AboutLebeeba from "./pages/AboutLebeeba";
import SupportedAreas from "./pages/SupportedAreas";
import ChangePassword from "./pages/ChangePassword";
import ChangePhoneNumber from "./pages/ChangePhoneNumber";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandConditions from "./pages/TermsandConditions";

const PrivateRoute = ({ component, ...rest }) => {
  let isAuthed = useSelector((state) => state.UserReducer.loggedIn);

  let uToken = localStorage.getItem("u-token");
  let gToken = localStorage.getItem("g-token");

  if (uToken || gToken) {
    isAuthed = true;
  }

  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AuthRoute = ({ component, ...rest }) => {
  const isAuthed = useSelector((state) => state.UserReducer.loggedIn);

  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : (
          <div>{React.createElement(component, props)}</div>
        )
      }
    />
  );
};

const Routers = () => {
  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/search" component={Search} />
      <Route exact path="/order/:id" component={Order} />
      <Route exact path="/forgetPassword" component={ForgetPassword} />
      <Route exact path="/verifyPhone" component={VerifyPhone} />
      <Route exact path="/categories" component={Categories} />
      <Route exact path="/favorite" component={Favorite} />
      <Route exact path="/about" component={AboutLebeeba} />
      <Route exact path="/supportedareas" component={SupportedAreas} />

      <Route exact path="/Category/:id" component={CategoryPage} />

      <Route exact path="/passwordChangedSuccessfully" component={PasswordChangedSuccessfully} />
      <Route exact path="/acconutCreatedSuccessfully" component={AcconutCreatedSuccessfully} />
      <Route exact path="/myCart" component={MyCart} />
      <Route exact path="/chooseLocationMap" component={ChooseLocationMap} />
      <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
      <Route exact path="/termsandConditions" component={TermsandConditions} />

      {/* auth Routes */}
      <AuthRoute exact path="/signup" component={Signup} />
      <AuthRoute exact path="/login" component={Login} />
      <AuthRoute exact path="/resetPassword" component={ResetPassword} />

      {/* private Routes */}
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/changePassword" component={ChangePassword} />
      <PrivateRoute exact path="/changePhoneNumber" component={ChangePhoneNumber} />

      <PrivateRoute exact path="/myorders" component={MyOrders} />

      {/* if route not found */}
      {/* <Redirect to="/" /> */}
    </>
  );
};
export default Routers;
