import React from "react";
import "./index.scss";

const PrivacyPolicy = (props) => {
  return (
    <div className="PrivacyPolicy-page">
      <div>
        <p style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "14.0pt", color: "#0d0d0d" }}>Labeeba Privacy Policy</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "14.0pt", color: "#0d0d0d" }}>&amp;</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "14.0pt", color: "#0d0d0d" }}>&nbsp;Data Protection</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "14.0pt", color: "#0d0d0d" }}>&nbsp;</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}>
          <strong>
            <em>
              <span style={{ fontSize: "14.0pt", color: "#0d0d0d" }}>We Are GDPR Compliant</span>
            </em>
          </strong>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
          </strong>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>We have drafted this document to protect your </span>
          </strong>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            privacy &nbsp;, since you will be using our website and services we will collect, store, use and protect your personal information. When you see the word &nbsp;"personal information" it
            will &nbsp;mean information that can be associated with yourself or a specific person and can be used to identify that person.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            We collect personal information from you when you use or visit &nbsp;Labeeba, &nbsp;(" LABEEBA") or use its services. By providing us with your personal information you provide us with an
            irrevocable non transferrable license to process your this information in accordance with the terms of our privacy policy.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            We may amend our privacy policy from time to time to help improve the services and/or to increase the protection of your data and/or to comply with different jurisdictions in the world and
            best practices thereof, you will be informed by sending the new Policy as a notification or via email ,the revised version will be effective at the time we post it and, following such
            posting, your continued use of the &nbsp;LABEEBA or our services, &nbsp;will constitute your acceptance to the terms of our revised privacy policy.
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Our privacy policy covers the following topics:</span>
          </strong>
        </p>
        <ol>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Our collection of your personal information.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Our use of your personal information.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Your use of your and other users’ personal information.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Accessing, reviewing and amending your personal information.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Third party LABEEBA links.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Cookies.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> No spam or spoof emails.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> Protecting your personal information.</span>
          </li>
          <li>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}> How you can contact us about privacy questions.</span>
          </li>
        </ol>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Our collection of your personal information</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              As part of your registration to use our services ("Labeeba"), you will be asked to provide us with certain personal information, your name, your email address and/or telephone number ,
              your date of birth and other identifying information. Additionally, in order for us to verify your identity, we may need to request from you valid proof of identification (e.g. in the
              form of a copy of your passport and/or resident’s visa and/or work or tourism permits And/or national ID card and/or driver’s license).
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>We will also need to collect selected financial information from you, such as your credit card and/or bank account details.</span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              You will need to submit the above mentioned information into the category set for you to establish your account through our platform. Please be note that we will not ask you for any
              further information outside the official email sent from us to you to either confirm or update or verify such information from time to time.{" "}
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              Please note that we may use your Internet protocol (or IP) address on for the enhancement of our services to you , we may analyze , study and reform all sorts of information for the
              purpose stated herein.
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              We may also collect information about your computer (for example, browser type) and navigation information (for example, the pages you visit on the our platform) along with the times
              that you access it.
            </span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            Finally, we may and you accept and permit us to collect in total confidentiality &nbsp;additional information from or about you in other ways not specifically described here. For example,
            we may collect information related to your contact with our customer support team or store results when you respond to a survey. We may also collect feedback ratings and other comments
            relating to your use of our services and/or platform.{" "}
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Our use of your personal information</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>By granting us your personal information stated above , you allow us to use such data as following:</span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            Provide services and customer support to you; to measure and improve our services to you; to prevent illegal activities; troubleshoot problems; collect fees; provide you with promotional
            emails and verify information you give us with third parties. For example, we may share some of the personal information you give us with banks or credit card authorization, processing and
            verification services or with third parties for fraud screening purposes.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            verifying your identity, verifying your eligibility to register as a user of the our platform and/or services, processing your registration as a user, providing you with a log-in ID to
            access ,maintaining and managing your registration; performing research or statistical analysis in order to improve the content and layout of the platform and/or services, to improve our
            product offerings and services and for marketing and promotional purposes; we may use your name, phone number, residential address, email address and fax number ("Marketing Data") to
            provide notices, surveys, product alerts, communications and other marketing materials to you relating to goods , services and requests offered by us on the Platform.
            <br />
            <br />
          </span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              Though we make every effort to preserve your privacy, we may need to disclose your personal information to law enforcement agencies, government agencies or other third parties where we
              are compelled to do so by court order or similar legal procedure; where we are required to disclose your personal information to comply with law; where we are cooperating with an ongoing
              law enforcement investigation or where we have a good faith belief that our disclosure of your personal information is necessary to prevent physical harm or financial loss, to report
              suspected illegal activity or to investigate a possible violation of our Terms.
            </span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            You further agree that we may disclose and transfer your personal information to service providers engaged by us to assist us with providing you with our services (including but not
            limited to data entry, database management, promotions, products and services alerts, dark markets, super markets , hyper markets, &nbsp;delivery services, payment extension services, and
            membership authentication and verification services) ("Service Providers"). These Service Providers are under a duty of confidentiality to us and are only permitted to use your personal
            data in connection with the purposes specified above, and not for their own purposes (including direct marketing).&nbsp;
            <br />
            You agree that we may disclose and transfer, for the purposes specified above, your personal data to other affiliated companies.
            <br />
            <br />
            Without prejudice to the above guarantees by our platform , and for the&nbsp; right and legal reasons and for the purpose of enhancing and/or improving our services and/or our platform ,
            our accessibility and/or usability Any personal data supplied by you will be saved by us and will be accessible with the same conditions as we have and will comply with by; our employees
            and/or Service Providers that that we contracted for the above purposes , &nbsp;and any third party referred to above.
            <br />
            <br />
          </span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              We do not share or disclose any of your personal information to third parties in the normal course of doing business and will only share or disclose your personal information with third
              parties in accordance with this privacy policy.
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              By registering on the Platform and/or visiting the website and/or using our services, you grant us your permission to send promotional emails about our services and emails announcing
              changes to, and new features on, the platform. If, at any time, you decide that you do not wish to receive any such emails, you can opt out of receiving such emails by simply informing
              us through the means stated in each email or as amended from time to time.
            </span>
          </li>
        </ul>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Your use of your and other users’ personal information</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              We cannot guarantee the privacy of your personal information when you share personal information with other members of this Platform or any other.
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>This privacy policy does not cover your release of your personal information to another member in the platform or elsewhere.</span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Accessing, reviewing and amending your personal information</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              If your personal information changes in any way or is incorrectly presented on the Platform, you should immediately update or correct your personal information through the means provided
              in the platform.
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              Please note that we shall save and hold your personal information during and following the end of your use of the LABEEBA as required to comply with law, for technical troubleshooting
              requirements, to prevent fraud, to assist in any legal investigations and to take any other actions otherwise permitted by law.
            </span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Hyper links, URLs and icons for other parties on the Platform;</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            Labeeba may contain links to other entities and/or websites and/or &nbsp;Labeebas, therefore this Privacy Policy does not apply to these third party &nbsp;Labeebas. The privacy policies of
            those other parties may differ from ours, and we have no control over the information that you submit to those third parties. You should read the relevant privacy policy for those third
            party sites before responding to any offers, products or services advertised by those parties.Please be aware that we are not responsible for the privacy practices of other bodies and/or
            entities.{" "}
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Cookies</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              We use 'cookies' technology (small computer files placed on your computer’s hard drive). When you go to particular pages on the Platform, the cookie identifies your browser with a
              unique, random number.
            </span>
          </li>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              The cookies we use do not reveal any personal information about you. Cookies help us improve your experience of the services and also help us understand which parts are the most popular.
              You are always free to decline our cookies if your browser permits, although doing so may interfere with your use of the Platform.
            </span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>No spam or spoof emails</span>
          </strong>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            We do not tolerate spam. To report Site related spam or spoof emails, please forward the email to Report@labeeba.com &nbsp;. You may not use our communication tools to send spam or
            otherwise send content that would violate our Terms. We automatically scan and may manually filter messages to check for spam, viruses, phishing attacks and other malicious activity or
            illegal or prohibited content.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Protecting your personal information</span>
          </strong>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            By providing us with personal information, you consent to the transfer of your personal information to, and its storage on, our servers located in the ({" "}
          </span>
          <em>
            <span style={{ fontSize: "12.0pt" }}>Germany</span>
          </em>
          <em>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>)</span>
          </em>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            . We take every precaution to safeguard all your personal information from unauthorized access, use or disclosure. All personal information is encrypted. However, the Internet is not a
            secure medium and we cannot guarantee the privacy of your personal information. You must enter your username and password each time you want to access your account. Choose your password
            carefully using unique numbers, letters and special characters. Never share your username and password with anyone. If you are concerned that your username or password has been
            compromised, please contact our customer support team immediately and ensure you change your password by logging onto the platform.
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>How you can contact us about privacy questions</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            If you have questions or concerns about our collection and use of your personal information, please contact our customer support team at support@Labeeba.com &nbsp;.
          </span>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: '"Calibri",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <strong>
            <span style={{ fontSize: "14.0pt", color: "#0d0d0d" }}>Data Privacy Conditions:</span>
          </strong>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ol>
          <li>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "black" }}>
              <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
            </span>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>Introduction</span>
            </strong>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>.</span>
          </li>
        </ol>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            These conditions form part of the Terms of Use govern your use of Labeeba the (“ LABEEBA”), these conditions set out each party’s obligations in relation Personal Data received from you in
            connection with the provision of your use of the &nbsp;LABEEBA. Some provisions of these conditions are region-specific and will only apply in respect of the regions or countries
            specified. In some countries, further country-specific terms are required; We may amend our data privacy conditions at any time by posting a revised version as stated in the our privacy
            policy.
          </span>
        </p>
        <p style={{ marginBottom: "0in", textAlign: "justify", lineHeight: "normal", direction: "rtl", unicodeBidi: "embed" }}>
          <span style={{ fontSize: "9.0pt", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ol start={2}>
          <li>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "black" }}>
              <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
            </span>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>Definitions</span>
            </strong>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>.</span>
          </li>
        </ol>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Capitalized terms used in these conditions shall have the meanings assigned to them unless the context requires otherwise.</span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            "<strong>Data Protection Law</strong>" means any and all applicable laws and/or regulations relating to privacy and/or data protection in relation to the Processing of the Personal Data,
            including any amendments or supplements to or replacements of such laws and/or regulations and including without limitation and as applicable: (i) the EU Directive on Data Protection
            (95/46/EC) and the EU Directive on Privacy and Electronic Communications (2002/58/EC); (ii) any national laws implementing such directives; (iii) the GDPR; and (iv) any Equivalent Law
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            “<strong>Data Subject</strong>” means a natural person who is identified, or who can be identified directly or indirectly, in particular by reference to an identification number or to one
            or more factors specific to his or her physical, physiological, genetic, mental, economic, cultural or social identity, or, if different, the meaning given to this term or nearest
            equivalent term under applicable local data protection law. For the purpose of these conditions, Data Subjects may be the user, or any person whose his/her Personal Data has been used by
            the user to sign up and/or use the &nbsp;LABEEBA ;
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            “<strong>Equivalent Law</strong>” means the laws and/or regulations of any country outside the EEA that are intended to provide equivalent protections for Personal Data (or the nearest
            equivalent term under applicable data protection law and/or regulation) of Data Subjects as the GDPR, including without limitation, the data protection laws of Jersey, Macau, Morocco,
            Switzerland and the United Kingdom;
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            “<strong>GDPR</strong>” means the General Data Protection Regulation (EU) 2016/679 and any laws and/or regulations implementing or made pursuant to such regulation;
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            “<strong>Personal Data</strong>” means any information that can be used, directly or indirectly, alone or in combination with other information, to identify an individual, or, if
            different, the meaning given to this term or nearest equivalent term under applicable local data protection law.
          </span>
        </p>
        <p style={{ marginBottom: "15.0pt", textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            &nbsp;“<strong>Processing</strong>” of Personal Data means any operation or set of operations which is performed upon Personal Data, whether or not by automatic means, such as collection,
            recording, organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination,
            restriction, erasure or destruction, or, if different, the meaning given to this term or nearest equivalent term under Applicable local data protection law.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "86%", tabStops: ".15in", margin: "1.65pt 0in 0in 0in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ol start={3}>
          <li>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "black" }}>
              <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
            </span>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>Data privacy</span>
            </strong>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>. </span>
          </li>
        </ol>
        <p style={{ textAlign: "justify", lineHeight: "86%", tabStops: ".15in", margin: "1.65pt 0in 0in 0in" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>&nbsp;</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>Compliance with law.</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "1.65pt 0in 0in .25in" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            We will comply with Applicable Data Protection Law in processing your Personal Data in connection with the use of the &nbsp;LABEEBA.{" "}
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "86%", margin: "1.65pt 0in 0in 0in" }}>
          <span style={{ fontSize: "9.0pt", lineHeight: "86%", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>Compliance with GDPR/Equivalent Laws.</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "1.65pt 0in 0in .25in" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            Without limiting Condition 3.1 (Compliance with law), to the extent that the Processing of Personal Data is subject to the GDPR or any Equivalent Law and rules: (i) each party is
            responsible for its own compliance with Applicable Data Protection Law; and (ii) the &nbsp;LABEEBA user confirms that any of the user Personal Data that it provides to the &nbsp;LABEEBA
            has been Processed fairly and lawfully, is accurate and is relevant for the purposes for which it is being provided and the &nbsp;LABEEBA may rely on the user's compliance with such
            undertaking and, where Applicable, assistance from the user pursuant to Condition 3.4 (Legal basis for Processing).
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "86%", margin: "1.65pt 0in 0in 0in" }}>
          <span style={{ fontSize: "9.0pt", lineHeight: "86%", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "86%", margin: "0in 0in 0in .25in" }}>
          <span style={{ fontSize: "9.0pt", lineHeight: "86%", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>Purpose limitation.</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "1.65pt 0in 0in .25in" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            The user hereby authorizes the &nbsp;LABEEBA to Process user Personal Data in accordance with these conditions and to the extent reasonably required for the relevant Permitted Purposes for
            the period of time reasonably necessary for the relevant Permitted Purposes.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "86%", margin: "0in 0in 0in .25in" }}>
          <span style={{ fontSize: "9.0pt", lineHeight: "86%", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>Legal basis for Processing.</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "86%", margin: "0in 0in 0in .25in" }}>
          <strong>
            <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>&nbsp;</span>
          </strong>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "86%", margin: "1.65pt 0in 0in 0in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "86%", color: "#0d0d0d" }}>3.4.1 Except as noted in Condition 3.4.2:</span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>When</span>
            </strong>
            <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
              {" "}
              <strong>the user is the Data Subject</strong>:{" "}
            </span>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "0in 0in 0in 40.5pt" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            To the extent that the user is the Data Subject of user Personal Data Processed by the &nbsp;LABEEBA, then the user consents to the &nbsp;LABEEBA’s Processing of all of such user Personal
            Data.{" "}
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "0in 0in 0in 40.5pt" }}>
          <strong>
            <span style={{ fontSize: "9.0pt", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>When the user is not the Data Subject:</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "0in 0in 0in 40.5pt" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            The user warrants that to the extent required by applicable law or regulation, it has provided notice to and obtained consent from such Data Subjects in relation to the &nbsp;LABEEBA’s
            Processing of their Personal Data as described in terms and conditions (and will provide such notice or obtain such consent in advance of providing similar information in future). The user
            further warrants that any such consent has been granted by these Data Subjects for the period reasonably required for the realization of the relevant Permitted Purposes.{" "}
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "0in 0in 0in 40.5pt" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "1.65pt 0in 0in 0in" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            3.4.2 To the extent that the Processing of Personal Data is subject to the GDPR or any Equivalent Law then the provisions of this Condition 3.4.2 shall apply only:{" "}
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "9.0pt", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>When the u is the Data Subject:</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            when the user is the Data Subject of the Personal Data Processed by the Labeeba, then the user hereby acknowledges that the Labeeba will Process user Personal Data as set forth in privacy
            policy or as it may notify to the user from time to time and the Platform may seek consent from the user prior to conducting certain Processing activities from time to time as its legal
            basis for Processing user Personal Data under the GDPR or any Equivalent Law.
          </span>
        </p>
        <p style={{ textAlign: "justify", lineHeight: "normal", margin: "12.2pt 0in 0in 0in" }}>
          <span style={{ fontSize: "9.0pt", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>&nbsp;</span>
        </p>
        <ul>
          <li>
            <strong>
              <span style={{ fontSize: "9.0pt", fontFamily: '"Tahoma",sans-serif', color: "#0d0d0d" }}>
                <span style={{ font: '7.0pt "Times New Roman"' }}> </span>
              </span>
            </strong>
            <strong>
              <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>When the user is not the Data Subject:</span>
            </strong>
          </li>
        </ul>
        <p style={{ textAlign: "justify", lineHeight: "normal" }}>
          <span style={{ fontSize: "12.0pt", color: "#0d0d0d" }}>
            the user warrants that it shall provide notice to, and shall seek consent from (and promptly upon the &nbsp;LABEEBA’s request shall provide evidence to the &nbsp;LABEEBA of having provided
            such notices and/or obtained such consents), such Data Subjects in relation to the &nbsp;LABEEBA’s Processing of their Personal Data in accordance with any instructions of the
            &nbsp;LABEEBA from time to time (which may include the form and the manner in which a notice is to be provided, or any consent is to be obtained).&nbsp; In connection with the foregoing,
            the user warrants that it will provide Data Subjects with a copy of the privacy policy or any other terms and conditions as the &nbsp;LABEEBA may notify to the user from time to time).
          </span>
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{ color: "#0d0d0d" }}>&nbsp;</span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
