import React, { useState, useEffect } from "react";
import "./index.scss";

import Category from "../../components/Category";
import CategoryPlaceholder from "../../PlaceHolders/CategoryPlaceholder";
import { GET_REQ } from "../../helpers/requests";

const Categories = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const getData = async () => {
    setLoading(true);
    let res = await GET_REQ(`categories`, { sort: "sort" });
    setData(res?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="Categories-page">
      <p className="title">التصنيفات</p>
      <div className="content">
        {loading ? (
          <>
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
            <CategoryPlaceholder />
          </>
        ) : (
          <>
            {data.map(({ _id, name, color, image }) => (
              <Category key={_id} id={_id} colord={color} title={name} imgSrc={image} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Categories;
