import React, { useState, useEffect } from "react";
import "./index.scss";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Message } from "semantic-ui-react";
import { POST_REQ, PUT_REQ } from "../../helpers/requests";
import { validateForm } from "../../helpers/validation";
import useQueryString from "use-query-string";
import { update_user_data } from "../../redux/actions/user";

const ChangePhoneNumber = ({ params }) => {
  const userState = useSelector((state) => state.UserReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [query] = useQueryString(window.location);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });

  const onSubmit = async () => {
    if (formData && !loading) {
      let validation = validateForm({ ...formData });

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setLoading(true);
      let res = await POST_REQ(`users/send-code`, {
        ...formData,
      });

      if (res?.data?.status === "success") {
        setLoading(false);
        dispatch(update_user_data({ changePhone: formData?.phone }));

        history.push("/verifyphone");
      } else {
        setLoading(false);
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
      setLoading(false);
    }
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="change-phone-page">
      <p className="title">تغيير رقم الهاتف</p>
      <p className="desc">سنقوم بأرسال رسالة لتأكيد رقم الهاتف الجديد</p>

      <Form className="form" onSubmit={onSubmit} error={errors?.form?.error}>
        <Form.Field>
          <Form.Input
            required
            name="phone"
            type="number"
            error={errors?.phone ? { content: errors?.phone, pointing: "below" } : false}
            onChange={handleChange}
            placeholder="رقم الهاتف الجديد"
          />
        </Form.Field>

        {errors?.form?.error && <Message error header="Error" content={errors?.form?.message} />}

        <Button className="secondary submit-button" type="submit" loading={loading}>
          ارسال
        </Button>
      </Form>
    </div>
  );
};

export default ChangePhoneNumber;
