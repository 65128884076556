import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import { GET_REQ } from "../../helpers/requests";
import "./index.scss";

const SupportedAreas = () => {
  const [areas, setAreas] = useState(null);
  const [loading, setLoading] = useState(null);

  const getDate = async () => {
    setLoading(true);
    let res = await GET_REQ(`areas`);
    setLoading(false);

    if (res?.data?.status === "success") {
      setAreas(res?.data?.data);
    }
  };

  useEffect(() => {
    getDate();

    return () => {};
  }, []);

  return (
    <div className="SupportedAreas-page">
      <p className="title">المناطق المخدومة</p>
      <div className="content">
        {areas?.map(({ name }, index) => (
          <div className="area" key={index}>
            <Image className="area-icon" src="/assets/icons/location_white.svg" alt="" />
            <p className="area-title">{name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupportedAreas;
