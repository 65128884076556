import React, { useEffect, useState } from "react";
import "./index.scss";
import { GET_REQ } from "../../helpers/requests";
import ProductPlaceholder from "../../PlaceHolders/ProductPlaceholder";
import Product from "../../components/Product";
import { useSelector } from "react-redux";
import { checkFavoriteProduct } from "../../helpers/helper";

const Favorite = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);

  const getData = async () => {
    setLoading(true);
    let res = await GET_REQ(`products/favorite`);
    setLoading(false);

    if (res?.data?.status === "success") {
      setProducts(res?.data?.data);
    } else {
      setProducts(null);
    }
  };

  const removeFavoriteProduct = (productId) => {
    if (productId && products) {
      let updatedProducts = products.filter(({ _id }) => _id !== productId);
      setProducts(updatedProducts);
    }
  };
  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="Favorite-page">
      <p className="title">المفضلة</p>
      <div className="content">
        {loading ? (
          <>
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
            <ProductPlaceholder />
          </>
        ) : (
          <>
            {products?.length > 0 ? (
              products?.map(
                (
                  { title_ar, size, thumbnail, price, _id, favorite_users, market_price },
                  index
                ) => (
                  <Product
                    favorite_users={favorite_users}
                    key={index}
                    imgSrc={thumbnail}
                    title={`${title_ar}`}
                    price={price}
                    market_price={market_price}
                    _id={_id}
                    removeFavoriteProduct={removeFavoriteProduct}
                  />
                )
              )
            ) : (
              <p>لا يوجد لديك أي منتجات </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Favorite;
