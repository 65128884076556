import React, { useState } from "react";
import "./index.scss";
import Qty from "../Qty";
import { useDispatch } from "react-redux";
import { Image, Loader, Placeholder } from "semantic-ui-react";
import { add_to_cart, update_cart } from "../../redux/actions/cart";
import { DELETE_REQ } from "../../helpers/requests";

const CartProduct = ({
  _id,
  title,
  price,
  imgSrc,
  defaultQty,
  index,
  notAvailable = false,
  cart_id,
  out_of_stock,
}) => {
  const dispatch = useDispatch();
  const [qtyCount, setqtyCount] = useState(defaultQty || 1);
  const [qtyLoading, setQtyLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(true);
  const [deleteItemloading, setDeleteItemLoading] = useState(false);

  const increaseQty = () => {
    setQtyLoading(true);

    let success_callback = () => {
      setQtyLoading(false);
      setqtyCount(qtyCount + 1);
    };

    let error_callback = () => {
      setQtyLoading(false);
    };

    dispatch(add_to_cart({ _id, quantity: 1 }, success_callback, error_callback));
  };

  const decreaseQty = () => {
    if (qtyCount - 1 <= 0) {
      return;
    }

    setQtyLoading(true);

    let success_callback = () => {
      setQtyLoading(false);
      setqtyCount(qtyCount - 1);
    };

    let error_callback = () => {
      setQtyLoading(false);
    };

    dispatch(add_to_cart({ _id, quantity: -1 }, success_callback, error_callback));
  };

  const deleteItem = async () => {
    setDeleteItemLoading(true);
    // index
    let res = await DELETE_REQ(`cart/${cart_id}/${index}`);

    if (res?.data?.status === "success") {
      dispatch(update_cart(res?.data?.data));
    }

    setTimeout(() => {
      setDeleteItemLoading(false);
    }, 1000);
  };

  const ImageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <div className="cart-product-container">
      <div className="right-content">
        {
          <Image
            className="product-image"
            style={{ display: imageloading ? "none" : "block" }}
            src={imgSrc}
            onLoad={ImageLoaded}
          />
        }
        {imageloading && (
          <Placeholder>
            <Placeholder.Image style={{ width: 76, height: 71 }} />
          </Placeholder>
        )}
        {out_of_stock && !imageloading && (
          <div className="out-of-stock">
            <p>مباع</p>
          </div>
        )}
      </div>

      <div className="center-content">
        <p className="title">{title}</p>

        <div className="qty-container">
          <Qty
            loading={qtyLoading}
            notAvailable={notAvailable}
            count={qtyCount}
            onChange={(count) => setqtyCount(count)}
            index={index}
            increase={increaseQty}
            decrease={decreaseQty}
          />
        </div>
      </div>

      <div className="left-content">
        {deleteItemloading ? (
          <Loader active size="small" inline="centered" style={{ marginLeft: "unset" }} />
        ) : (
          <img className="delete-icon" onClick={deleteItem} alt="" src="/assets/icons/delete.svg" />
        )}
        <p className="price">
          {price}
          <span> دينار</span>
        </p>
      </div>
    </div>
  );
};

export default CartProduct;
