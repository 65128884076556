import actionTypes from "../actionTypes";

export const start_search = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.START_SEARCH,
      payload,
    });
  };
};

export const done_search = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DONE_SEARCH,
    });
  };
};

export const clear_search = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_SEARCH,
    });
  };
};
