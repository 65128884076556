import { GET_REQ } from "../../helpers/requests";
import actionTypes from "../actionTypes";

export const fetch_addresses = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ADDRESSES,
      payload: { loading: true },
    });

    let res = await GET_REQ(`addresses`);

    if (res?.data?.status === "success") {
      dispatch({
        type: actionTypes.FETCH_ADDRESSES,
        payload: {
          loading: false,
          addresses: res?.data?.data,
          selected_address: res?.data?.data && res?.data?.data[0],
        },
      });
    } else {
      dispatch({
        type: actionTypes.FETCH_ADDRESSES,
        payload: { loading: false },
      });
    }
  };
};
