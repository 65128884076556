import React from "react";
import "./index.scss";

const AboutLebeeba = () => {
  return (
    <div className="AboutLebeeba-page">
      <p className="title">عن لبيبة</p>
      <div className="content">
        <p>
          لبيبة؛ منصة إلكترونية تضم كافة المنتجات التي تحتاجونها في مكان واحد تصلك لباب المنزل... يوفر تطبيق لبيبة مجموعة متنوعة من المنتجات عالية الجودة بداية من الخضروات والفواكه إلى منتجات الألبان
          ومواد التنظيف وغيرها الكثير من المستلزمات المنزلية الأخرى. استمتع بتجربة مميزة، سهلة، وسريعة ... ووفر عناء البحث وتسوق من بين آلاف المنتجات كافة احتياجاتك دون مغادرة المنزل. هنا في لبيبة؛
          رضى علمائنا يعتبر من أهم أول أولوياتنا، لذا نوفر خدمة عملاء على مدار الساعة لدعمك في كل خطوة أثناء وبعد التسوق.
        </p>
      </div>
    </div>
  );
};

export default AboutLebeeba;
