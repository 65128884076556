import React, { useState, useEffect, useRef } from "react";
import "./index.css";

const VerifyInput = ({ onChange, hasError, errorMessage, placeholder }) => {
  const [code, setCode] = useState({ 0: "", 1: "", 2: "", 3: "" });
  const [finalCode, setFinalCode] = useState("");

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  const focusInput2 = (e) => {
    if (e.key === "Backspace") {
      setCode({ ...code, 0: "" });

      input1.current.focus();
    } else {
      input2.current.focus();
    }
  };

  const focusInput3 = (e) => {
    if (e.key === "Backspace") {
      setCode({ ...code, 1: "" });

      input1.current.focus();
    } else {
      input3.current.focus();
    }
  };

  const focusInput4 = (e) => {
    if (e.key === "Backspace") {
      setCode({ ...code, 2: "" });

      input2.current.focus();
    } else {
      input4.current.focus();
    }
  };

  const focusInputLast = (e) => {
    if (e.key === "Backspace") {
      setCode({ ...code, 3: "" });
      input3.current.focus();
    } else {
      input4.current.blur();
    }
  };

  // const focusInput2 = (e) => {
  //   if (e.key==="Backspace") {
  //     input1.current.focus();
  //   }else{
  //     input2.current.focus();
  //   }
  // };
  // const focusInput3 = () => input3.current.focus();
  // const focusInput4 = () => input4.current.focus();
  // const unFocusInput4 = () => input4.current.blur();

  useEffect(() => {
    setFinalCode(code["0"] + code["1"] + code["2"] + code["3"]);
    return () => {};
  }, [code]);

  useEffect(() => {
    if (finalCode.length === 4) {
      onChange && onChange(finalCode);
    }
    return () => {};
  }, [finalCode]);

  return (
    <div className="verify-input-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        ref={input4}
        type="text"
        maxLength="1"
        size="1"
        min="0"
        max="9"
        pattern="[0-9]{1}"
        onKeyUp={(e) => focusInputLast(e)}
        value={code["3"]}
        onChange={({ target: { value } }) => setCode({ ...code, 3: value })}
        placeholder="0"
      />
      <input
        ref={input3}
        type="text"
        maxLength="1"
        size="1"
        min="0"
        max="9"
        pattern="[0-9]{1}"
        onKeyUp={(e) => focusInput4(e)}
        value={code["2"]}
        onChange={({ target: { value } }) => setCode({ ...code, 2: value })}
        placeholder="0"
      />
      <input
        ref={input2}
        type="text"
        maxLength="1"
        size="1"
        min="0"
        max="9"
        pattern="[0-9]{1}"
        onKeyUp={(e) => focusInput3(e)}
        value={code["1"]}
        onChange={({ target: { value } }) => setCode({ ...code, 1: value })}
        placeholder="0"
      />
      <input
        tabindex="1"
        ref={input1}
        autoFocus
        type="text"
        maxLength="1"
        size="1"
        min="0"
        max="9"
        pattern="[0-9]{1}"
        onKeyUp={(e) => focusInput2(e)}
        value={code["0"]}
        onChange={({ target: { value } }) => setCode({ ...code, 0: value })}
        placeholder="0"
      />
    </div>
  );
};

export default VerifyInput;
