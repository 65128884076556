import React, { useEffect, useState } from "react";
import "./index.scss";
import ChooseLocationPlaceHolder from "../../PlaceHolders/ChooseLocationPlaceHolder";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";

const ChooseLocation = ({ time }) => {
  const history = useHistory();
  const addressesState = useSelector((state) => state.AddressesReducer);

  const [loading, setLoading] = useState(true);
  const [addressText, setAddressText] = useState(null);

  const getData = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const goToChooseLocationMap = () => {
    history.push(`/chooseLocationMap`);
  };

  useEffect(() => {
    setAddressText(addressesState?.selected_address?.formatted_address);
    return () => {};
  }, [addressesState]);

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="ChooseLocation-container">
      <div className="content" onClick={goToChooseLocationMap}>
        <img className="icon" alt="" src="/assets/icons/location_white.svg" />
        {loading || addressesState?.loading ? (
          <Loader active size="small" inline="centered" style={{ marginRight: 15, marginTop: 2 }} />
        ) : (
          <p className="title">{addressText || "اختر العنوان"}</p>
        )}
      </div>

      {addressesState?.selected_address?.formatted_address && (
        <div className="time">
          {loading || addressesState?.loading ? <ChooseLocationPlaceHolder /> : <p>{time} دقائق</p>}
        </div>
      )}
    </div>
  );
};

export default ChooseLocation;
