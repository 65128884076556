import * as actionTypes from "../actionTypes";

const initialState = {
  addresses: null,
  selected_address: null,
  loading: false,
};

const AddressesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADDRESSES:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default AddressesReducer;
