import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.scss";
import { Form, Button, Message } from "semantic-ui-react";
import { POST_REQ } from "../../helpers/requests";
import { validateForm } from "../../helpers/validation";
import { update_user_data } from "../../redux/actions/user";
import useQueryString from "use-query-string";

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query] = useQueryString(window.location);
  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });

  const goToLoginPage = () => {
    if (query.r === "cart") {
      history.push("/login?r=cart");
    } else {
      history.push("/login");
    }
  };

  const onSubmit = async () => {
    if (formData && !loading) {
      let validation = validateForm({ ...formData });

      if (formData?.password !== formData?.newpassword) {
        setErrors({ ...errors, password: "يجب ان تكون كلمة المرور متساوية" });
        return;
      }

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setLoading(true);
      let res = await POST_REQ(`users/signup`, { ...formData });
      setLoading(false);

      if (res?.data?.status === "success") {
        dispatch(update_user_data({ phone: formData?.phone }));
        if (query.r === "cart") {
          history.push("/verifyphone?r=cart");
        } else {
          history.push("/verifyphone");
        }
      } else {
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
    }
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="signup-page">
      <p className="title">انشاء حساب</p>
      <p className="desc">أنشيء حسابك الآن وكن جزء من عائلة لبيبة </p>

      <Form className="form" onSubmit={onSubmit} error={errors?.form?.error}>
        <Form.Field>
          <Form.Input required error={errors?.username ? { content: errors?.username, pointing: "below" } : false} onChange={handleChange} name="username" placeholder="الاسم" />
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            error={errors?.phone ? { content: errors?.phone, pointing: "below" } : false}
            onChange={handleChange}
            name="phone"
            type="number"
            placeholder="رقم الهاتف"
            maxLength="10"
          />
        </Form.Field>
        <Form.Field>
          <Form.Input required error={errors?.email ? { content: errors?.email, pointing: "below" } : false} onChange={handleChange} name="email" placeholder="الايميل" />
        </Form.Field>
        <Form.Field>
          <Form.Input required error={errors?.password ? { content: errors?.password, pointing: "below" } : false} type="password" onChange={handleChange} name="password" placeholder="كلمة المرور" />
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            name="newpassword"
            type="password"
            onChange={handleChange}
            placeholder="تأكيد كلمة المرور"
            error={errors?.newpassword ? { content: errors?.newpassword, pointing: "below" } : false}
          />
        </Form.Field>

        {errors?.form?.error && <Message error header="Error" content={errors?.form?.message} />}

        <Button loading={loading} className="secondary submit-button" type="submit">
          انشاء حساب
        </Button>
        <p className="login-no-account">
          هل لديك حساب؟<span onClick={goToLoginPage}>تسجيل الدخول</span>
        </p>
      </Form>
    </div>
  );
};

export default Signup;
