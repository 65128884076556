import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./index.scss";

const OrderCard = ({ id, date, price }) => {
  const history = useHistory();

  const goToOrderDetails = () => {
    history.push(`order/${id}`);
  };

  return (
    <div className="OrderCard">
      <div className="header">
        <p className="title">طلب #{id} </p>
        <p className="date">{moment(date).format("YYYY MM DD")}</p>
      </div>
      <div className="content">
        <div className="order-value">
          <p className="label">قيمة الطلب</p>
          <p className="price">
            {price.toFixed(2)}
            <span>دينار</span>
          </p>
        </div>
        <p className="order-details" onClick={goToOrderDetails}>
          تفاصيل الطلب
        </p>
      </div>
    </div>
  );
};

export default OrderCard;
