import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeSvg from "../../Assets/Icons/FooterBar/HomeSvg";
import SearchSvg from "../../Assets/Icons/FooterBar/SearchSvg";
import FavouriteSvg from "../../Assets/Icons/FooterBar/FavouriteSvg";
import CartSvg from "../../Assets/Icons/FooterBar/CartSvg";

const Footer = () => {
  const history = useHistory();
  const cartState = useSelector((state) => state.CartReducer);

  const [currentPath, setCurrentPath] = useState(false);

  const [hideFooter, setHideFooter] = useState(false);

  const goToCart = () => {
    cartState?._id && history.push("/mycart");
  };

  const goToFavourite = () => {
    history.push("/favorite");
  };

  const goToSearch = () => {
    history.push("/search");
  };

  const goToHome = () => {
    history.push("/");
  };

  const getCurrentPath = (path) => {
    return currentPath === "/" + path;
  };

  useEffect(() => {
    setCurrentPath(history?.location?.pathname);
    return () => {};
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (history?.location?.pathname === "/chooseLocationMap") {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
    return () => {};
  }, [history?.location?.pathname]);

  return (
    <div className="Footer-container">
      {!hideFooter && (
        <div className="content">
          <Button
            className={["cart", cartState?.totalPrice > 0 ? "have-price" : ""].join(" ")}
            onClick={goToCart}
            loading={cartState?.loading}
          >
            {cartState?.totalPrice - cartState?.discount || 0.0}
            <CartSvg
              className="svg-icon"
              stroke={cartState?.totalPrice > 0 ? "#3cc08f" : "#253b69"}
            />
          </Button>

          <Button
            className={[
              "link favourite",
              getCurrentPath("favorite") ? "secondary current" : "",
            ].join(" ")}
            onClick={goToFavourite}
          >
            {getCurrentPath("favorite") ? "المفضلة" : ""}
            <FavouriteSvg
              className="svg-icon"
              stroke={getCurrentPath("favorite") ? "#FFF" : "#253b69"}
            />
          </Button>

          <Button
            onClick={goToSearch}
            className={["link search", getCurrentPath("search") ? "secondary current" : ""].join(
              " ",
            )}
          >
            {getCurrentPath("search") ? "البحث" : ""}
            <SearchSvg
              className="svg-icon"
              stroke={getCurrentPath("search") ? "#FFF" : "#253b69"}
            />
          </Button>
          <Button
            onClick={goToHome}
            className={["link home", getCurrentPath("") ? "secondary current" : ""].join(" ")}
          >
            {getCurrentPath("") ? "الرئيسية" : ""}

            <HomeSvg className="svg-icon" stroke={getCurrentPath("") ? "#FFF" : "#253b69"} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Footer;
