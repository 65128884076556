import React from "react";
import { Image, Loader } from "semantic-ui-react";
import "./index.scss";

const Qty = ({ count, notAvailable, loading, decrease, increase, allowDeleteItem, deleteItem }) => {
  return (
    <div
      className="qty-container"
      style={
        (notAvailable ? { opacity: 0.2 } : {},
        allowDeleteItem ? { marginTop: 8, width: "100%", height: 34 } : {})
      }
    >
      <button
        disabled={loading}
        style={allowDeleteItem ? { width: "100%" } : {}}
        onClick={() => {
          increase && increase();
        }}
        className="qty-plus"
      >
        +
      </button>

      <div
        className="qty-number"
        style={
          allowDeleteItem
            ? {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 4,
              }
            : {}
        }
      >
        {loading ? (
          <Loader active size="tiny" inline="centered" style={{ width: 22, marginTop: 5 }} />
        ) : (
          count
        )}
      </div>

      {allowDeleteItem && deleteItem && count <= 1 ? (
        <div className="qty-minus delete" style={allowDeleteItem ? { width: "100%" } : {}}>
          <Image
            src={`/assets/icons/trash-delete.svg`}
            disabled={loading}
            onClick={() => {
              deleteItem && deleteItem();
            }}
          />
        </div>
      ) : (
        <button
          style={allowDeleteItem ? { width: "100%" } : {}}
          disabled={loading}
          onClick={() => {
            decrease && decrease();
          }}
          className="qty-minus"
        >
          -
        </button>
      )}
    </div>
  );
};

export default Qty;
