import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Form, Button, Input } from "semantic-ui-react";
import { validateForm } from "../../helpers/validation";
import { update_user_data } from "../../redux/actions/user";
import { PATCH_REQ } from "../../helpers/requests";

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState = useSelector((state) => state.UserReducer);

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });
  const goToChangePhoneNumber = () => {
    history.push("/changePhoneNumber");
  };

  const goToChangePassword = () => {
    history.push("/changepassword");
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    if (formData && !loading) {
      let validation = validateForm({ ...formData });

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setLoading(true);
      let res = await PATCH_REQ(`users`, { ...formData });
      setLoading(false);

      if (res?.data?.status === "success") {
        dispatch(update_user_data({ ...res?.data?.data }));
        setFormData(null);
      } else {
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
      setLoading(false);
    }
  };

  return (
    <div className="Profile-page">
      <p className="title">حسابي</p>
      <Form className="form" onSubmit={onSubmit} error={errors?.form?.error}>
        <Form.Field>
          <label className="field-label">الإسم</label>

          <Form.Input
            defaultValue={userState?.username}
            required
            placeholder="إسم المستخدم"
            error={errors?.username ? { content: errors?.username, pointing: "below" } : false}
            onChange={handleChange}
            name="username"
          />
        </Form.Field>
        <Form.Field>
          <label className="field-label">رقم الهاتف</label>
          <div className="chnage-container">
            <Input
              disabled
              label={{ basic: true, content: "00962" }}
              labelPosition="left"
              placeholder="790000000"
              value={userState?.phone}
              className="phone-number-input"
            />
            <p className="change" onClick={goToChangePhoneNumber}>
              تغيير
            </p>
          </div>
        </Form.Field>
        <Form.Field>
          <label className="field-label">الإيميل</label>
          <Form.Input
            required
            placeholder="البريد الإلكتروني"
            error={errors?.email ? { content: errors?.email, pointing: "below" } : false}
            onChange={handleChange}
            name="email"
            className="email-input"
            defaultValue={userState?.email}
          />
        </Form.Field>

        <Form.Field>
          <label className="field-label">كلمة المرور</label>
          <div className="chnage-container">
            <Input
              className="password-input"
              placeholder="كلمة المرور"
              value={"********"}
              disabled
              type="password"
            />
            <p className="change" onClick={goToChangePassword}>
              تغيير
            </p>
          </div>
        </Form.Field>
        <div className="submit-container">
          <Button
            disabled={!formData}
            loading={loading}
            className="secondary submit-button"
            type="submit"
          >
            حفظ التعديلات
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Profile;
