import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Button, Form, Image, Input, Loader, Message } from "semantic-ui-react";
import CartProduct from "../../components/CartProduct";
import { POST_REQ } from "../../helpers/requests";
import { clear_cart, clear_cart_without_address, update_cart } from "../../redux/actions/cart";
import { useHistory } from "react-router";
import { validateForm } from "../../helpers/validation";

const MyCart = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cartState = useSelector((state) => state.CartReducer);
  const userState = useSelector((state) => state.UserReducer);
  const addressesState = useSelector((state) => state.AddressesReducer);

  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [errors, setErrors] = useState({
    form: { error: false, message: "" },
  });

  const goToPickLocation = () => {
    history.push("chooseLocationMap?q=cart");
  };

  const goToHomePage = () => {
    history.push("/");
  };

  const createOrder = async () => {
    if (!userState?.loggedIn) {
      history.push("login?r=cart");
    }

    if (cartState?._id) {
      setLoading(true);

      let res = await POST_REQ(`orders/${cartState?._id}`);
      setLoading(false);

      if (res?.data?.status === "success") {
        if (res?.data?.data?._id) {
          if (res?.data?.within_working_hours) {
            history.push(`order/${res?.data?.data?._id}?q=added`);
          } else {
            history.push(`order/${res?.data?.data?._id}?q=added&out=true`);
          }
        }
        dispatch(clear_cart());
      }
    }
  };

  const addCoupon = async () => {
    if (formData && !loading) {
      let validation = validateForm({ ...formData });

      if (validation) {
        setErrors({ ...validation });
        return;
      }

      setCouponLoading(true);
      let res = await POST_REQ(`cart/coupon`, {
        coupon: formData?.coupon,
      });
      setCouponLoading(false);

      if (res?.data?.status === "success") {
        dispatch(update_cart(res?.data?.data));
      } else {
        setErrors({ ...errors, form: { error: true, message: res?.data?.message } });
      }
    }
  };

  const handleChange = (e, { name, value }) => {
    setErrors({ form: { error: false, message: "" } });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="MyCart-page">
      <div className="page-content">
        <p className="title">سلة المشتريات</p>
        <div className="content">
          {cartState?.items?.length > 0 ? (
            cartState?.items?.map(({ price, title, size, product_id, thumbnail, quantity, out_of_stock }, index) => (
              <CartProduct key={product_id} price={price} title={title} defaultQty={quantity} imgSrc={thumbnail} _id={product_id} index={index} out_of_stock={out_of_stock} cart_id={cartState?._id} />
            ))
          ) : (
            <div className="empty-cart">
              <Image className="image" src={"/assets/icons/empty_cart.svg"} />
              <p className="title">لم تقم بأضافة اي منتجات حتى الآن!</p>
              <Button className="secondary button" onClick={goToHomePage}>
                تصفح المنتجات
              </Button>
            </div>
          )}
        </div>
        {cartState?.items?.length > 0 && (
          <>
            <div className="shipping-price">
              <p className="label">قيمة التوصيل</p>
              <p className="price">{cartState?.shipping_price <= 0 ? "مجاناً" : cartState?.shipping_price + " دينار "}</p>
            </div>
            <div className="sum">
              <p className="title">المجموع</p>
              <p className={["price", cartState?.loading ? "loading" : ""].join(" ")}>
                {cartState?.loading ? <Loader active size="small" inline="centered" style={{ width: 60 }} /> : cartState?.totalPrice - cartState?.discount || 0}

                <span className="label"> دينار</span>
              </p>
            </div>
          </>
        )}

        {cartState?.coupon ? (
          <>
            <div className="shipping-price">
              <p className="label">تم تفعيل الكود</p>
              <p className="price">{cartState?.coupon?.code}</p>
            </div>
          </>
        ) : (
          <>
            <p className="coupon-label">هل يوجد لديك كوبون؟</p>
            <div>
              <div className="coupon-container">
                <Form onSubmit={addCoupon} error={errors?.form?.error}>
                  <Form.Field>
                    <Form.Input
                      loading={couponLoading}
                      iconPosition="left"
                      icon
                      placeholder="ادخل الكوبون"
                      name="coupon"
                      required
                      onChange={handleChange}
                      error={errors?.coupon ? { content: errors?.coupon, pointing: "below" } : false}
                    >
                      <input style={{ textAlign: "right" }} />
                      <Button type="submit" disabled={couponLoading} loading={couponLoading} className="secondary submit-button">
                        تفعيل
                      </Button>
                    </Form.Input>
                  </Form.Field>
                  {errors?.form?.error && <Message error header="Error" content={errors?.form?.message} />}
                </Form>
              </div>
            </div>
          </>
        )}

        {cartState?.items?.length > 0 && (
          <div className="order-time">
            <div className="content">
              {addressesState?.selected_address?.formatted_address && <p className="title">سيتم توصيل الطلبية خلال 10 دقائق الى:</p>}
              <div className="bottom">
                {addressesState?.selected_address?.formatted_address ? (
                  <p className="location">{addressesState?.selected_address?.formatted_address}</p>
                ) : (
                  <p className="location" onClick={goToPickLocation} style={{ cursor: "pointer", marginTop: -12 }}>
                    أضف عنوان
                  </p>
                )}
                {addressesState?.selected_address?.formatted_address && (
                  <span onClick={goToPickLocation} className="change">
                    تغيير
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {cartState?.items?.length > 0 && (
        <div className="footer">
          <Button disabled={!cartState?._id || cartState.totalPrice <= 0 || !cartState?.address} loading={cartState?.loading || loading} className="secondary submit-button" onClick={createOrder}>
            اتمام عملية الشراء
          </Button>
        </div>
      )}
    </div>
  );
};

export default MyCart;
