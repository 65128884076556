export const compare = (key, order = "asc") => {
  return function innerSort(a, b) {
    if (key !== "price" && (!a.hasOwnProperty(key) || !b.hasOwnProperty(key))) {
      return 0;
    }
    let varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    let varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    if (key === "created_at" || key === "updated_at") {
      varA = Date.parse(a[key]);
      varB = Date.parse(b[key]);
    }

    if (key === "price") {
      let price_a = startingFrom(a.units);
      let price_b = startingFrom(b.units);
      varA = price_a;
      varB = price_b;
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const startingFrom = (units) => {
  let prices = [];
  for (var unit in units) {
    let price = units[unit].item_details.price;
    price = Number(price);
    if (price) {
      prices.push(price);
    } else {
      continue;
    }
  }
  if (prices.length) return Math.min(...prices);
  return 0;
};

export const startingFromWithVendor = (units) => {
  let prices = [];
  let vendors_prices = {};
  for (var unit in units) {
    let price = units[unit].item_details.price;
    price = Number(price);
    if (price) {
      prices.push(price);
      vendors_prices[price] = units[unit].vendor_details.label;
    } else {
      continue;
    }
  }
  if (prices.length) {
    let lowest = Math.min(...prices);
    let vendor = vendors_prices[lowest];
    return [lowest, vendor];
  }
  return false;
};

export const lowestItemVendor = (price, units) => {
  for (var unit in units) {
    let unit_price = units[unit].item_details.price;
    if (Number(price) === Number(unit_price)) {
      return units[unit].vendor_details;
    } else {
      continue;
    }
  }
};

export const best_store = (units) => {
  let best = [];
  for (var unit in units) {
    let cost = units[unit].item_details.price;
    cost = Number(cost);
    if (cost) best.push({ store: units[unit].vendor_details, cost, vendor_id: unit });
  }
  best = best.sort(compare("cost", "asc"));

  return best[0];
};

export const getPriceRange = (units) => {
  let best = [];
  for (var unit in units) {
    let cost = units[unit].item_details.price;
    cost = Number(cost);
    if (cost) best.push({ store: units[unit].vendor_details, cost, vendor_id: unit });
  }
  best = best.sort(compare("cost", "asc"));
  const min = best[0]["cost"];
  const max = best[best.length - 1]["cost"];
  return { min, max };
};

const getParams = function (search) {
  let params = {};

  let query = search.substring(1);
  let vars = query.split("&");

  for (let i = 0; i < vars.length; i++) {
    if (vars[i] === "") continue;
    let pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  return params;
};

const _stringfyParams = (paramsObj) => {
  let result = "?";
  for (const param in paramsObj) {
    result += `${param}=${paramsObj[param]}&`;
  }

  return result.substring(0, result.length - 1);
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const addParameter = (name, number, search) => {
  let params = getParams(search);
  params[name] = number;

  return _stringfyParams(params);
};

export const removeParameter = (name, search) => {
  let params = getParams(search);
  delete params[name];

  return _stringfyParams(params);
};

export const extractParams = (object, map) => {
  if (!map) {
    return object;
  }
  let result = {};
  for (let k in map) {
    if (object[map[k]]) {
      result[k] = object[map[k]];
    }
  }
  return result;
};

export const toFixed = (value) => parseFloat(parseFloat(value).toPrecision(12)).toFixed(2);

export const zoneOptions = [
  { value: "1", label: "Abdali" },
  { value: "2", label: "Abdoun" },
  { value: "3", label: "Abdullah Ghosheh Street" },
  { value: "4", label: "Abu Nsair" },
  { value: "5", label: "Airport Road Dunes Bridge" },
  { value: "6", label: "Airport Road Hay Al Sahabah" },
  { value: "7", label: "Airport Road Madaba Bridge" },
  { value: "8", label: "Airport Road Manaseer Gs" },
  { value: "9", label: "Airport Road Nakheel Village" },
  { value: "10", label: "Al Bayader" },
  { value: "11", label: "Al Bnayyat" },
  { value: "12", label: "Al Fuhais" },
  { value: "13", label: "Al Gardens" },
  { value: "14", label: "Al Hummar" },
  { value: "15", label: "Al-Hurriyah" },
  { value: "16", label: "Al Jandaweel" },
  { value: "17", label: "Al Kursi" },
  { value: "18", label: "Al Muqabalain" },
  { value: "19", label: "Al Rabiah" },
  { value: "20", label: "Al Rawabi" },
  { value: "21", label: "Al Sahel" },
  { value: "22", label: "Al Shmaisani" },
  { value: "23", label: "Al Swefieh" },
  { value: "24", label: "Alweibdeh" },
  { value: "25", label: "Arjan" },
  { value: "26", label: "Dabouq" },
  { value: "27", label: "Dabouq Baccaloria" },
  { value: "28", label: "Dabouq Ferdous" },
  { value: "29", label: "Dahiet Al Ameera Salma" },
  { value: "30", label: "Dahiet Al Ameer Rashed" },
  { value: "31", label: "Dahiet Al Hussain" },
  { value: "32", label: "Dahiet Al Rasheed" },
  { value: "33", label: "Dahiet Al Yasmeen" },
  { value: "34", label: "Deir Ghbar" },
  { value: "35", label: "Eighth Circle" },
  { value: "36", label: "Fifth Circle" },
  { value: "37", label: "First Circle" },
  { value: "38", label: "Fourth Circle" },
  { value: "39", label: "Hay Al Rahmanieh" },
  { value: "40", label: "Hay Al Saleheen" },
  { value: "41", label: "Jabal Al Hussain" },
  { value: "42", label: "Jabal Amman" },
  { value: "43", label: "Jabal Qusoor" },
  { value: "44", label: "Jubaiha" },
  { value: "45", label: "Khalda" },
  { value: "46", label: "King Hussein Business Park" },
  { value: "47", label: "Madina Street" },
  { value: "48", label: "Marj El Hamam" },
  { value: "49", label: "Marka" },
  { value: "50", label: "Mecca Street" },
  { value: "51", label: "Naour" },
  { value: "52", label: "Second Circle" },
  { value: "53", label: "Seventh Circle" },
  { value: "54", label: "Shafa Badran" },
  { value: "55", label: "Sixth Circle" },
  { value: "56", label: "Sports City" },
  { value: "57", label: "Sweileh" },
  { value: "58", label: "Tariq Tabarboor" },
  { value: "59", label: "Third Circle" },
  { value: "60", label: "Tla Al Ali" },
  { value: "61", label: "Um El Summaq" },
  { value: "62", label: "Um Sous" },
  { value: "63", label: "Um Uthaiena" },
  { value: "64", label: "University Street" },
  { value: "65", label: "Wadi El Seer" },
  { value: "66", label: "Wadi Saqra" },
];

export const getZone = (value = null, name = "") => {
  if (!value && !name) return { value: "0", label: "select zone" };

  for (let i = 0; i < zoneOptions.length; i++) {
    const zoneObj = zoneOptions[i];

    if (zoneObj.value == `${value}` || zoneObj.label.toLowerCase() == name.toLowerCase())
      return zoneObj;
  }

  return { value: "0", label: "select zone" };
};

export const getHeaders = () => {
  const token = localStorage.getItem("token");
  let headers = { headers: {} };
  if (token) {
    headers = {
      headers: { Authorization: "Bearer " + token },
    };
  }
  return headers;
};

export const formatDate = (date) => {
  const day = new Date(date).getDate(),
    month = new Date(date).getMonth() + 1,
    year = new Date(date).getFullYear();

  return day + "/" + month + "/" + year;
};

export const setParams = (number, search) => {
  let params = getParams(search);
  params["page"] = number;

  let result = "?";
  for (const param in params) {
    result += `${param}=${params[param]}&`;
  }

  return result.substring(0, result.length - 1);
};

export const upperCaseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getAddressDetails = (lat, lng, res) => {
  const result = res.results[0].address_components;

  let save_address = {
    street_name: "",
    street_number: "",
    // name: name,
    city: "",
    country: "",
    location_map: {
      longitudeDelta: 0,
      latitudeDelta: 0,
      lng: 0,
      lat: 0,
    },
    country_code: 966,
    formatted_address: "",
  };

  for (let i = 0; i < result.length; i++) {
    const element = result[i];
    if (element.types[0] === "locality") {
      save_address["city"] = element.long_name;
    }

    save_address["location_map"]["lat"] = parseFloat(lat);
    save_address["location_map"]["lng"] = parseFloat(lng);

    save_address["formatted_address"] = res.results[0].formatted_address;
    if (save_address.hasOwnProperty(element.types[0])) {
      if (element.types[0] === "street_number") {
        save_address["street_number"] = parseFloat(element.long_name);
      } else save_address[element.types[0]] = element.long_name;
    }
  }

  return save_address;
};

export const getUserLocationWithPermission = (
  successCallback = () => {},
  errorCallback = () => {}
) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 3000,
    maximumAge: 0,
  };

  if (navigator?.permissions?.query) {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
      } else {
        errorCallback();
      }
      result.onchange = function () {
        // report(result.state);
      };
    });
  } else {
    _onGetCurrentLocation(successCallback, errorCallback);
  }
};

function _onGetCurrentLocation(successCallback, errorCallback) {
  if (navigator?.geolocation?.getCurrentPosition) {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position?.coords?.latitude) {
        successCallback(position);
      } else {
        errorCallback();
      }
    });
  } else {
    errorCallback();
  }
}

export const checkFavoriteProduct = (userId, usersIds) => {
  return userId && usersIds && usersIds?.includes(userId);
};

export const mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const checkIfUserOrGuestLoggedIn = () => {
  return localStorage.getItem("u-token") || localStorage.getItem("g-token");
};
