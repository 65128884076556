import React, { useState, useEffect } from "react";
import "./index.scss";
import { Image, Placeholder } from "semantic-ui-react";
import { getColor } from "../../static-data/categories";
import { useHistory } from "react-router";

const Category = ({ id, title, imgSrc, link, colord, width = 175, height = 140 }) => {
  const history = useHistory();

  const [darkColor, setDarkColor] = useState({});
  const [color, setColor] = useState({});
  const [imageloading, setImageLoading] = useState(true);

  const hexToRgba = (hex) => {
    // convert hex to rgb

    if (!hex) {
      setDarkColor(hex);
      return hex;
    }

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    result = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };

    // color to dark color

    let darkColor = { r: result.r - 8, g: result.g - 5, b: result.b - 3 };

    setDarkColor(darkColor);

    return darkColor;
  };

  const ImageLoaded = () => {
    setImageLoading(false);
  };

  const goToCategory = () => {
    history.push({
      pathname: `/Category/${id}`,
      state: {
        id: 7,
        color: "green",
      },
    });
  };

  useEffect(() => {
    let colorData;
    if (colord) {
      colorData = colord;
    } else {
      colorData = getColor();
    }

    setColor(colorData);
    hexToRgba(colorData);

    return () => {};
  }, []);

  return (
    <div
      className="Category-container"
      style={{ backgroundColor: color, maxWidth: width, height }}
      onClick={goToCategory}
    >
      <div className="title" style={width === 175 ? { fontSize: 20 } : { fontSize: 16 }}>
        {title}
      </div>

      {width === 175 ? (
        <svg className="svg-bottom-line" width="175" height="140">
          <polygon
            points="0,140 0,110 175,60 175,140"
            className="polygon"
            style={{ fill: `rgb(${darkColor.r},${darkColor.g},${darkColor.b})` }}
          />
        </svg>
      ) : (
        <svg className="svg-bottom-line" width="132" height="140">
          <polygon
            points="0,140 0,110 132,60 132,140"
            className="polygon"
            style={{ fill: `rgb(${darkColor.r},${darkColor.g},${darkColor.b})` }}
          />
        </svg>
      )}

      <Image
        className="image"
        src={imgSrc}
        style={{ display: imageloading ? "none" : "block", marginTop: width === 175 ? -34 : -10 }}
        onLoad={ImageLoaded}
      />
      {imageloading && (
        <Placeholder style={{ zIndex: 20, width: 80, height: 80, marginTop: 10 }}>
          <Placeholder.Image style={{ width: 80, height: 80 }} />
        </Placeholder>
      )}
    </div>
  );
};

export default Category;
