import React, { useState } from "react";
import "./index.scss";
import VerifyInput from "../../components/VerifyInput";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { PATCH_REQ, POST_REQ } from "../../helpers/requests";
import { login, update_user_data } from "../../redux/actions/user";
import useQueryString from "use-query-string";

const VerifyPhone = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.UserReducer);
  const [query] = useQueryString(window.location);

  const [verifyCode, setVerifyCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reSendCodeClicked, setReSendCodeClicked] = useState(false);

  const validateInput = async () => {
    if (verifyCode.length < 3) {
      setError("please fill all inputs");
      return;
    }

    submitVerify();
  };

  const submitVerify = async () => {
    let windowUrl = window.location.search;

    setLoading(true);

    if (userState?.changePhone) {
      let res = await PATCH_REQ(`users/info`, {
        phone: userState?.changePhone,
        code: verifyCode,
      });
      setLoading(false);

      if (res?.data?.status === "success") {
        dispatch(update_user_data({ phone: userState?.changePhone, changePhone: null }));
        history.push("/");
      } else {
        setError(res?.data?.message);
      }
    } else {
      let res = await POST_REQ(`users/verify`, {
        phone: userState?.phone,
        code: verifyCode,
      });
      setLoading(false);

      if (res?.data?.status === "success") {
        if (windowUrl && windowUrl.includes("reset-password") && userState?.reset_token) {
          history.push(`/resetpassword?code=${verifyCode}`);
        } else {
          dispatch(login({ ...res?.data?.data, token: res?.data?.total }));
          if (query.r === "cart") {
            history.push("/mycart");
          } else {
            history.push("/");
          }
        }
      } else {
        setError(res?.data?.message);
      }
    }
  };

  const reSendVerifyCode = async () => {
    setReSendCodeClicked(true);
    if (userState?.changePhone) {
      await POST_REQ(`users/send-code`, {
        phone: userState?.changePhone,
      });
    } else {
      await POST_REQ(`users/resend-code`, {
        phone: userState?.phone,
      });
    }
    setTimeout(() => {
      setReSendCodeClicked(false);
    }, 5000);
  };

  return (
    <div className="verify-phone-page">
      <p className="title">ادخل الكود</p>
      <p className="desc">ادخل الكود المكون من اربع خانات للتأكيد</p>

      <VerifyInput
        errorMessage={error}
        onChange={(code) => {
          setVerifyCode(code);
          setError(null);
        }}
      />
      <Button loading={loading} onClick={validateInput} className="secondary submit-button">
        تأكيد
      </Button>
      {reSendCodeClicked ? (
        <p className="send-code-again-success">تم إعادة ارسال رمز التحقق</p>
      ) : (
        <p className="send-code-again">
          لم تستلم الكود؟<span onClick={reSendVerifyCode}>إعادة ارسال</span>
        </p>
      )}
    </div>
  );
};

export default VerifyPhone;
