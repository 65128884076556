import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import SearchProduct from "../../components/SearchProduct";
import { useDispatch, useSelector } from "react-redux";
import { GET_REQ } from "../../helpers/requests";
import OrderCardPlaceHolder from "../../PlaceHolders/OrderCardPlaceHolder";
import { done_search } from "../../redux/actions/search";
import Suggestions from "./Suggestions";
import useQueryString from "use-query-string";

const Search = () => {
  const SearchState = useSelector((state) => state.SearchReducer);

  const dispatch = useDispatch();

  const updateQuery = (path) => {
    window.history.pushState(null, document.title, path);
  };

  const productsContainerRef = useRef();

  const [query, setQuery] = useQueryString(window.location, updateQuery);

  const [ShowSuggestions, setShowSuggestions] = useState(false);
  const [showNoResultFound, setShowNoResultFound] = useState(false);

  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reatchTheLimit, setReatchTheLimit] = useState(false);

  const productsLimt = 20;

  const getData = async (searchValue) => {
    setShowSuggestions(false);
    setLoading(true);
    setShowNoResultFound(false);
    setCurrentPage(1);

    let res = await GET_REQ("products", {
      search: searchValue,
      limit: productsLimt,
      sort: "sort",
      page: 1,
    });
    setLoading(false);

    if (res?.data?.data?.length > 0) {
      setSearchResult(res?.data?.data);
      dispatch(done_search());
    } else {
      dispatch(done_search());
      setSearchResult(null);
      // setShowSuggestions(true);
      setShowNoResultFound(true);
    }
  };

  const getDataOnScroll = async (searchValue) => {
    let page = currentPage + 1;

    setCurrentPage(page);

    setFetching(true);

    let res = await GET_REQ("products", {
      search: searchValue,
      limit: productsLimt,
      sort: "sort",
      page,
    });
    setFetching(false);
    if (res?.data?.data?.length > 0) {
      setSearchResult([...searchResult, ...res?.data?.data]);
    } else {
      setReatchTheLimit(true);
    }
  };

  const onScrollPage = ({ target }) => {
    if (target?.scrollHeight - target?.scrollTop - 600 <= 500) {
      if (searchResult?.length > productsLimt - 1 && !fetching && !reatchTheLimit) {
        if (query?.q) {
          getDataOnScroll(query?.q);
        }
      }
    }
  };

  // const searchSuggestion = (value) => {
  //   dispatch(start_search(value));
  //   getData(value);
  // };

  useEffect(() => {
    if (SearchState?.value) {
      setQuery({ q: SearchState?.value });
      // setQuery({ q: SearchState?.value, p: page });
    }

    return () => {};
  }, [SearchState]);

  useEffect(() => {
    if (query?.q) {
      getData(query?.q);
    }
    if (query?.p) {
      // setPage(query?.p);
    }

    return () => {};
  }, [query]);

  return (
    <div className="search-page">
      {showNoResultFound && <div style={{ padding: "20px 40px" }}>لا يوجد نتائج مطابقة للبحث</div>}

      {ShowSuggestions && <Suggestions />}
      <div className="products" ref={productsContainerRef} onScroll={onScrollPage}>
        {loading ? (
          <>
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
            <OrderCardPlaceHolder />
          </>
        ) : (
          <>
            {searchResult?.map(
              ({
                _id,
                price,
                title_ar,
                size,
                is_favorite,
                thumbnail,
                favorite_users,
                out_of_stock,
              }) => (
                <SearchProduct
                  key={_id}
                  imgSrc={thumbnail}
                  title={`${title_ar}`}
                  price={price}
                  favorite_users={favorite_users}
                  out_of_stock={out_of_stock}
                  id={_id}
                />
              )
            )}
          </>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default Search;
